import Url from "./Api";
import axios from 'axios';
import Swal from 'sweetalert2';
const authAxios = axios.create({
  baseURL: Url,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('jwtto')}`
  }
});
authAxios.interceptors.response.use(
  response => {
    // Aquí puedes verificar el contenido de la respuesta si es necesario
    // Si el servidor responde con un status específico en el cuerpo de la respuesta
    if (response.data.status === 'Token is Expired') {
      // Si el token ha expirado, redirigir al usuario a la página de inicio de sesión
      localStorage.removeItem('jwtto');
      window.location.href = '/login'; // O usa history.push('/login') si estás en un componente de React
      return Promise.reject(new Error('Token is Expired')); // Opcional, para manejar la promesa rechazada
    }
    return response;
  },
  error => {
    if (error.response) {
      // Si el servidor respondió con un código de estado fuera de 2xx
      console.error('Error en la respuesta:', error.response);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `Error: ${error.response.status} - ${error.response.data.message || 'Ocurrió un error'}`,
      });
    } else if (error.request) {
      // Si no se recibió respuesta del servidor
      console.error('Error en la solicitud:', error.request);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No se recibió respuesta del servidor. Por favor, intenta de nuevo más tarde.',
      });
    } else {
      // Otros errores
      console.error('Error:', error.message);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `Ocurrió un error: ${error.message}`,
      });
    }
    // if (response.data.status === 'Token is Expired') {
      // Si el token ha expirado, redirigir al usuario a la página de inicio de sesión
      // localStorage.removeItem('jwtto');
      // window.location.href = '/login'; // O usa history.push('/login') si estás en un componente de React
      // return Promise.reject(new Error('Token is Expired')); // Opcional, para manejar la promesa rechazada
    // }
    // Aquí puedes manejar otros errores que no estén relacionados con el token expirado
    return Promise.reject(error);
  }
);
export default authAxios;