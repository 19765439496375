import React, { useRef, useEffect } from 'react';
import Swal from 'sweetalert2';
import authAxios from "../../services/Token";
import hoy from "../../services/FechaActual";
import { useParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faEdit, faFilter, faPlus, faTrashAlt, faImage, faInfo, faEye, faDownload, faUpload, faPrint, faSearch, faKey, faGasPump, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
// import Option from './SelectRelacion';
import { saveAs } from 'file-saver';
import FormularioCrear from './Crear';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import $ from "jquery";
import { PDFViewer } from '@react-pdf/renderer';
// import PDF from './VerPdf';
import axios from 'axios';
import Url, { apiImage } from "../../services/Api";
// import Calendario from './Calendario';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
//import Configuracion from './Configuracion';
// import PDFDetalle from './DetallePdf';
import { addDays, eachDayOfInterval } from 'date-fns';

const Index = () => {
  const { idmenu } = useParams();
  const tableRef = useRef(null);
  const tableHeader = useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingOpen, setIsLoadingOpen] = React.useState(false);
  const [isLoadingTable, setIsLoadingTable] = React.useState(false);
  const [pdfBase64, setPdfBase64] = React.useState('');
  // const [categoria, setCategoria] = React.useState('');
  const [ItemTabla, setLista] = React.useState([])
  const [ItemTablaExp, setListaExp] = React.useState([])
  // const [Campos, setCampo] = React.useState([])
  const [Tabla, setTabla] = React.useState([])
  const [TablaModal, setTablaModal] = React.useState([])
  const [TablaExp, setTablaExp] = React.useState([])
  const [Tabs, setTab] = React.useState([])
  const [Relaciones, setRelaciones] = React.useState([])
  const [Titulo, setTitulo] = React.useState("Cargando..")
  const [actual, setActual] = React.useState("1")
  const [ultimo, setUltimo] = React.useState("0")
  const [pagina, setPagina] = React.useState("0")
  const [Tamano, setTamano] = React.useState("lg")
  const [Configuracion, setConfiguracion] = React.useState([])
  const [VistaCampo, setVistaCampo] = React.useState("")
  const [RelacionItems, setRelacionItems] = React.useState("")

  const [Campo, setInput] = React.useState("")
  const [IdCampo, setInputId] = React.useState("")
  //setInputTabla(tabla);
  //setInputTablaId(id);
  const [InputTabla, setInputTabla] = React.useState("")
  const [InputTablaId, setInputTablaId] = React.useState("")

  const [NombreTabla, setNombre] = React.useState("")
  const [botondescargar, setBoton] = React.useState("")
  // para las consultas de la tablas relacionales setInactivarBoton
  const [Rel_Tabla, setRelTabla] = React.useState([])
  const [Rel_ItemTabla, setRelLista] = React.useState([])
  const [Rel_actual, setRelActual] = React.useState("1")
  const [Rel_ultimo, setRelUltimo] = React.useState("0")
  // const [Rel_pagina, setRelPagina] = React.useState("0")
  const [TabsRel, setRelTab] = React.useState([])
  const [Rel_nav, setPestana] = React.useState([])
  // const [MostrarRelacion, setMostrarRelacion] = React.useState([])
  // const [TablaSelect, setTablaSelect] = React.useState([])
  const [CampoSeleccionado, setCampoSeleccionado] = React.useState("")

  const [Foranea, setForanea] = React.useState("")
  const [Llave, setLlave] = React.useState("")
  const [Menu, setMenu] = React.useState("")
  const [TraeValue, setValor] = React.useState("")
  const [RelacionId, setRelacionId] = React.useState("")
  const [Boton, setVerBoton] = React.useState("0")
  const [Detalles, setDetalles] = React.useState([])

  const [IdUsuario, setIdUsuario] = React.useState("0")

  const [Vehiculos, setVehiculos] = React.useState([])
  const [TablaProgramacion, setTablaProgramacion] = React.useState([])
  const [TablaProgramacionEjecucion, setTablaProgramacionEjecucion] = React.useState([])
  // const [Seleccionados, setSeleccionados] = React.useState([])
  const [elementosControl, setElementosControl] = React.useState([]);
  //permisos
  const [Registrar, setRegistrar] = React.useState("0")
  const [Editar, setEditar] = React.useState("0")
  const [Eliminar, setEliminar] = React.useState("0")
  // const [Autorizar, setAutorizar] = React.useState("0")
  const [Cargar, setCargar] = React.useState("0")
  const [Consultar, setConsultar] = React.useState("0")
  const [BtnConfiguracionn, setBtnConfiguracion] = React.useState("0")
  // const [BtnDatos, setBtnDatos] = React.useState("0")
  // en este caso se inactiva el boton en caso de concluir un servicio, pqr o una factura
  const [InactivarBoton, setInactivarBoton] = React.useState(0)

  // const [Latitude, setLatitude] = React.useState("0")
  // const [Longitude, setLongitude] = React.useState("0")
  const [searchTerm, setSearchTerm] = React.useState('');
  const [selectAll, setSelectAll] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState([]);
  const [selectedTime, setSelectedTime] = React.useState('');
  const [events, setEvents] = React.useState([]);
  const [selectedDates, setSelectedDates] = React.useState([]);
  const [time, setTime] = React.useState('');
  const [items, setItems] = React.useState([]);
  const [searchTermsSec, setSearchTermsSec] = React.useState(""); // Estado para almacenar el término de búsqueda
  const [filteredData, setFilteredData] = React.useState([]);
  // useEffect(() => {
  //   // Filtrar los datos en función del término de búsqueda
  //   const results = TablaModal.filter(item =>
  //     item.nombre.toLowerCase().includes(searchTermsSec.toLowerCase())
  //   );
  //   setFilteredData(results); // Actualizar el estado con los resultados filtrados
  // }, [searchTermsSec]); 
  const [formValues, setFormValues] = React.useState({
    nombre_clientes: '',
    nombreCliente: '',
    ciudad: '',
    estadoServicio: '',
    tarifa: '',
    tipoRecorrido: '',
    numeroContacto: '',
    numeroExpediente: '',
    fecha: '',
    horaInicial: '',
    direccionInicial: '',
    direccionFinal: '',
    cantidad: '',
    obs: '',
    items: [],
    nombreUsuario:'',
    apellidoUsuario:'',
    correoUsuario:'',
    telefonoUsuario:''
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleDateChange = (date) => {
    setSelectedDates((prevDates) => [...prevDates, date]);
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  const handleAddItem = () => {
    // Verifica si solo hay dos fechas seleccionadas (inicial y final)
    if (selectedDates.length === 2) {
      const [startDate, endDate] = selectedDates;

      // Genera un rango de fechas desde la inicial hasta la final
      const dateRange = eachDayOfInterval({
        start: startDate,
        end: endDate,
      });

      // Crea los nuevos elementos con cada fecha del rango
      const newItems = dateRange.map((date) => ({
        date: format(date, 'yyyy-MM-dd'),
        time,
      }));

      // Actualiza los elementos y el formulario
      setItems((prevItems) => [...prevItems, ...newItems]);
      setSelectedDates([]);
      setTime('');
      setFormValues((prevValues) => ({
        ...prevValues,
        items: [...prevValues.items, ...newItems],
        time: '',
      }));
    } else {
      // Lógica en caso de que no haya dos fechas seleccionadas
      console.log('Por favor selecciona solo dos fechas');
    }
  };
  const saveItem = async () => {
    console.log(formValues);
    const nombreUsuario = document.getElementsByName('nombreUsuario')[0].value;
    const apellidoUsuario = document.getElementsByName('apellidoUsuario')[0].value;
    const correoUsuario = document.getElementsByName('correoUsuario')[0].value;
    // const direccionUsuario = document.getElementsByName('direccionUsuario')[0].value;
    const telefonoUsuario = document.getElementsByName('telefonoUsuario')[0].value;

    // const hora = document.getElementById('hora').value;
    const username = localStorage.getItem('username');
    const nombre = localStorage.getItem('nombre');
    const admin = localStorage.getItem('admin');
    const idusuario = localStorage.getItem('idusuario');
    const identificacion = localStorage.getItem('identificacion');
    const cliente_id = localStorage.getItem('cliente_id');
    // Actualizar el estado con los nuevos valores
    setFormValues(prevValues => ({
      ...prevValues, // Mantener los valores actuales del estado
      nombreUsuario,
      apellidoUsuario,
      correoUsuario,
      telefonoUsuario,
      username,
      nombre,
      admin,
      idusuario,
      identificacion,
      cliente_id
    }));
    console.log(formValues);
    Swal.showLoading();
    try {
      Swal.close();
      const response = await authAxios.post(`exterior/guardar-servicios-cliente`, {
        ...formValues, 
        username,
        nombre,
        admin,
        idusuario,
        identificacion,
        cliente_id
      }); // Use async/await for cleaner syntax
      console.log(response.data);
      // Extrae los IDs de los servicios de la respuesta
      const serviceIds = response.data.serviceIds; // Asegúrate de que este campo exista

      // Genera el HTML de la tabla
      const tableHtml = `
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Servicio Creado Con exito</th>
          </tr>
        </thead>
        <tbody>
         <tr><td> EXPEDIENTE NUMERO: ${serviceIds}</td></tr>
        </tbody>
      </table>
    `;

      // Muestra la tabla en un SweetAlert
      Swal.fire({
        title: 'Servicios',
        html: tableHtml,
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });


    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };


  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
  };

  const filteredVehiculos = Vehiculos.filter((vh) =>
    vh.placa.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const fetchPdf = async (id) => {
    Swal.showLoading();
    try {
      const response = await authAxios.post(`exterior/traer-factura/${id}`); // Use async/await for cleaner syntax

      const pdfData = (response.data.response.base64);
      // const base64 = btoa(String.fromCharCode(...pdfData));
      setPdfBase64(pdfData);

      console.log('PDF base64:', pdfData); // Log the base64 data for verification
      Swal.close();
      // Abrir la ventana emergente después de obtener el PDF
      openPdfPopup();
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  const openPdfPopup = () => {
    const popupWindow = window.open('', '_blank', 'width=800,height=600');
    if (popupWindow) {
      const pdfContent = `<iframe src="data:application/pdf;base64,${pdfBase64}" width="100%" height="100%"></iframe>`;
      popupWindow.document.write(pdfContent);
    } else {
      console.error('Error opening popup window.');
    }
  };
  React.useEffect(() => {
    ListaTabla(idmenu, 1);
    ListaVehiculos();
  }, [idmenu]);



  const ListaTabla = async (idm, page) => {
    // Swal.showLoading();
    // let form = document.getElementById('buscador');

    // let u = localStorage.getItem('idusuario');
    // let formData = new FormData(form);
    // authAxios.post(`master/index/${idm}/${u}?page=${page}`, formData)
    //   .then((datosRespuesta) => {

    //     setNombre(datosRespuesta.data.tabla);

    //     var status = datosRespuesta.data.status;

    //     setTabla(datosRespuesta.data.modelo);
    //     setLista(datosRespuesta.data.data.data);
    //     // setCampo(datosRespuesta.data.campos);
    //     setTitulo(datosRespuesta.data.titulo);

    //     setTamano(datosRespuesta.data.formulario);
    //     setActual(datosRespuesta.data.data.current_page);
    //     setUltimo(datosRespuesta.data.data.last_page);
    //     setPagina(datosRespuesta.data.data.total);
    //     setTab(datosRespuesta.data.nav);
    //     //pasar paramentros de los permisos
    //     setRegistrar(datosRespuesta.data.permisos.registrar);
    //     setEditar(datosRespuesta.data.permisos.editar);
    //     setEliminar(datosRespuesta.data.permisos.eliminar);
    //     setConsultar(datosRespuesta.data.permisos.consultar);
    //     // setAutorizar(datosRespuesta.data.permisos.autorizar);
    //     setCargar(datosRespuesta.data.permisos.cargar);
    //     setBtnConfiguracion(datosRespuesta.data.permisos.configuracion);
    //     // setBtnDatos(datosRespuesta.data.permisos.datos);
    //     Swal.close();
    //     //this.setState({ datosCargados: true, load: '',Titulo: datosRespuesta.data.titulo,Relaciones: datosRespuesta.data.relaciones, Campos: datosRespuesta.data.campos, Tabla: datosRespuesta.data.modelo, ItemTabla: datosRespuesta.data.data.data, pagina: datosRespuesta.data.data.total, ultimo: datosRespuesta.data.data.last_page, actual: datosRespuesta.data.data.current_page });
    //   })
    //   .catch();
  }

  const ListaTablaExp = async (idm) => {
    let form = document.getElementById('buscador');
    let formData = new FormData(form);
    setBoton("Generando Listado..");
    authAxios.post(`master/exportar/${idm}`, formData)
      .then((datosRespuesta) => {

        Swal.close();
        var status = datosRespuesta.data.status;

        setBoton(<button type="button" className="btn btn-info btn-sm" id="btndescargar"> Descargar Listado</button>);
        setListaExp(datosRespuesta.data.data);
        setTablaExp(datosRespuesta.data.modelo);

      })
      .catch();
  }
  const ListaVehiculos = async () => {
    authAxios.post(`control/vehiculos-activos`)
      .then((datosRespuesta) => {
        var datosRespuesta = datosRespuesta.data.vehiculos;
        console.log(datosRespuesta);
        setVehiculos(datosRespuesta);

      })
      .catch();
  }


  const VerRegistros = async (idm, ide) => {
    // 
    Swal.showLoading();
    if (idm == 6) {
      const formElement = document.getElementById('form_registro');
      const divs = formElement.querySelectorAll('.hidden');

      divs.forEach((div) => {
        // 
        div.classList.remove('hidden');
      });
    }
    authAxios.get(`master/ver/${idm}/${ide}`)
      .then((datosRespuesta) => {
        // 
        let lista = datosRespuesta.data.result;
        setDetalles(lista);
        //dado el caso para inactivar el boton si el estado esta resuelt , solo pqr
        setInactivarBoton(datosRespuesta.data.editar);
        setVerBoton(1);
        let campos = datosRespuesta.data.modelo
        Swal.close();
        if (idm == 14) {
          // Acceder al valor del campo "Cliente"
          const clienteHidden = document.getElementById('nombre_clientes').value = lista.aseguradora;
          const clienteVisible = document.getElementById('nombre_clientes_name').value = lista.cliente_name;

          // Acceder al valor del campo "Ciudad"
          const ciudadHidden = document.getElementById('ciudad_nombre').value = lista.ciudad;
          const ciudadVisible = document.getElementById('ciudad_nombre_name').value = 'BOGOTA D.C';

          // id, nombre, tabla
          // Seleccionar(lista.estado_servicio,'nombre','modalidad_tari')
          // Acceder al valor del campo "Estado de Servicio"
          const estadoHidden = document.getElementById('estado_nombre').value = lista.estado_servicio;
          const estadoVisible = document.getElementById('estado_nombre_name').value = lista.estado_servicio_name;

          // Acceder al valor del campo "Tarifa"
          const tarifaHidden = document.getElementById('modalidad_nombre').value = lista.tarifa;
          const tarifaVisible = document.getElementById('modalidad_nombre_name').value = '';

          // Acceder al valor del campo "Tipo Recorrido"
          const tipoRecorridoHidden = document.getElementById('tipo_nombre').value = lista.tipo_recorrido;
          // const tipoRecorridoVisible = document.getElementById('tipo_nombre_name').value = '';

          // Acceder a otros campos como "Contacto Logística"
          const contactoLogistica = document.getElementsByName('numeroContacto')[0].value = '';
          const nombreLogistica = document.getElementsByName('nombreLogistica')[0].value;
          const cargoLogistica = document.getElementsByName('cargoLogistica')[0].value;

          // Acceder al campo de "Número Expediente"
          const numeroExpediente = document.getElementsByName('numeroExpediente')[0].value = lista.numero_expediente;

          // Acceder al campo de "Fecha"
          const fecha = document.getElementsByName('fecha')[0].value = lista.fecha;

          // Acceder al campo de "Hora Inicial"
          const horaInicial = document.getElementsByName('horaInicial')[0].value = lista.hora_inicial;

          // Acceder al campo de "Dirección Inicial"
          const direccionInicial = document.getElementsByName('direccionInicial')[0].value = lista.direccion_inicial;

          // Acceder al campo de "Dirección Final"
          const direccionFinal = document.getElementsByName('direccionFinal')[0].value = lista.direccion_final;

          // Acceder al campo de "Cantidad"
          const cantidad = document.getElementsByName('cantidad')[0].value = lista.cantidad;
        } else {
          campos.map((item, i) => (

            item.trae_tabla != null ?
              document.getElementById(item.nombre_campo + '_name').value = lista[item.nombre_campo + '_name'] : ''



          ))
        }

        setElementosControl(datosRespuesta.data.elementos_control);
        campos.map((item, i) => (

          item.tipo_dato == 'blob' ?
            document.getElementById('img' + item.nombre_campo).src = lista[item.nombre_campo] != null ? apiImage + lista[item.nombre_campo] : 'https://nosso.com/public/images/tipoproducto/default.jpg' : '',
          item.tipo_dato !== 'blob' ?
            document.getElementById(item.nombre_campo).value = lista[item.nombre_campo] : ''



        ))
        campos.map((item, i) => {
          if (item.tipo_dato === 'blob') {
            const imgElement = document.getElementById('imgd' + item.nombre_campo);
            if (imgElement) {
              imgElement.src = lista[item.nombre_campo] != null ? apiImage + lista[item.nombre_campo] : 'https://nosso.com/public/images/tipoproducto/default.jpg';
            }
          } else if (item.tipo_dato !== 'blob') {
            const detElement = document.getElementById('det' + item.nombre_campo);
            if (detElement) {
              detElement.value = lista[item.nombre_campo];
            }
          }
        });
        campos.forEach((item, i) => {
          const element = document.getElementById('det' + item.nombre_campo);
          if (element) {
            element.disabled = true;
          }
        });
        campos.map((item, i) => (
          item.tipo_dato == 'blob' ?
            document.getElementById('temp' + item.nombre_campo).value = lista[item.nombre_campo] : ''
        ))

      })
      .catch();


  };

  const traerkmver = async (idm, ide) => {
    document.getElementById('kmactualver').value = '';
    document.getElementById('kmactualverinputid').value = '';
    document.getElementById('kmnuevoact').value = '';
    Swal.showLoading();
    authAxios.get(`master/ver/${idm}/${ide}`)
      .then((datosRespuesta) => {
        // 
        let lista = datosRespuesta.data.result;
        let campos = datosRespuesta.data.modelo
        Swal.close();
        console.log(lista);
        document.getElementById('kmactualver').value = lista.odometro;
        document.getElementById('kmactualverinputid').value = lista.id;

      })
      .catch();
  }
  const actualizarkmvehiculo = async () => {
    Swal.showLoading();
    var kmvh = document.getElementById('kmnuevoact').value;
    var id = document.getElementById('kmactualverinputid').value;
    authAxios.get(`actualizarkmvehiculo/${id}/${kmvh}`)
      .then((datosRespuesta) => {
        Swal.close();
        // console.log(lista);
        // document.getElementById('kmactualver').value = lista.odometro;
        document.getElementById('kmactualver').value = '';
        document.getElementById('kmactualverinputid').value = '';
        document.getElementById('kmnuevoact').value = '';
      })
      .catch();
  }

  const verProgramacionVh = async (id) => {
    setTablaProgramacion([])
    setTablaProgramacionEjecucion([])
    Swal.showLoading();
    console.log(id);
    authAxios.get(`consultar-programacion-mantenimiento/${id}`)
      .then((datosRespuesta) => {
        Swal.close();
        // console.log(lista);
        // document.getElementById('kmactualver').value = lista.odometro;
        setTablaProgramacion(datosRespuesta.data.vehiculos);
        setTablaProgramacionEjecucion(datosRespuesta.data.ejecucion)
        console.log(TablaProgramacion);
      })
      .catch();
  }

  const cambioClaveUsuarioProveedor = async (idm, ide) => {
    // 
    Swal.showLoading();
    console.log("usuario" + ide);
    authAxios.get(`master/ver/${idm}/${ide}`)
      .then((datosRespuesta) => {
        Swal.close();
        setIdUsuario(ide);
      })
      .catch();


  };
  const guardarClaveProveedor = async (idm, ide) => {
    // 
    let form = document.getElementById('form_registro_password');
    let formData = new FormData(form);
    Swal.showLoading();
    console.log(IdUsuario);
    authAxios.post(`modulos/cambioclaveproveedor/${IdUsuario}`, formData)
      .then((datosRespuesta) => {
        Swal.close();
        Swal.fire('Con Exito!', 'Cambio de clave exitoso', 'success')

      })
      .catch();


  };

  const borrarRegistros = async (idm, id) => {
    let u = localStorage.getItem('idusuario');
    Swal.fire({
      title: 'Está seguro de Eliminar el registro?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Borrar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        authAxios.get(`master/borrar/${idm}/${id}/${u}`)
          .then((datosRespuesta) => {
            // if (datosRespuesta.Status) {
            // 
            Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
            ListaTabla(idm, 1);


          })
          .catch();
      } else if (result.isDenied) {
        Swal.fire('Cancelado', '', 'info')
      }
    })
  };

  const eliminarAsignacion = async (idm, id) => {
    let u = localStorage.getItem('idusuario');
    Swal.fire({
      title: 'Está seguro de Eliminar la asigancion del servicio?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Borrar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        authAxios.get(`control/deleteservicio/${id}`)
          .then((datosRespuesta) => {

            // 
            Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
            ListaTabla(idm, 1);


          })
          .catch();
      } else if (result.isDenied) {
        Swal.fire('Cancelado', '', 'info')
      }
    })
  }

  const BorrarRelacion = async (tabla, fk, id) => {
    Swal.fire({
      title: 'Está seguro de Eliminar el registro?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Borrar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        authAxios.get(`master/borrar_relacion/${tabla}/${id}`)
          .then((datosRespuesta) => {
            // 
            Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
            VerDatos(tabla, fk, 1);
          })
          .catch();
      } else if (result.isDenied) {
        Swal.fire('Cancelado', '', 'info')
      }
    })
  };
  const DescargarExcel = () => {
    Swal.showLoading();
    axios({
      url: Url + 'exportar/listado/' + idmenu,
      method: 'GET',
      responseType: 'blob'
    }).then(response => {
      // Crea un objeto Blob con los datos del archivo Excel
      const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });

      // Descarga el archivo utilizando FileSaver.js
      saveAs(blob, 'plantila' + idmenu + '.xlsx');
      Swal.close();
    });
  }
  const actualizarRelaciones = (tablaforanea, relacion_fk) => {
    // Llama a la función VerDatos con los parámetros proporcionados
    VerDatos(tablaforanea, relacion_fk, 1);
  };
  const save = async () => {
    Swal.showLoading();
    let form = document.getElementById('form_registro');
    let formData = new FormData(form);
    let u = localStorage.getItem('idusuario');
    authAxios.post(`master/registrar/${idmenu}/${u}`, formData)
      .then((datosRespuesta) => {

        var status = datosRespuesta.data.status;

        if (datosRespuesta.data.Status) {
          Swal.fire(
            'Registro!',
            datosRespuesta.data.msj,
            'success'
          )

          document.getElementById("form_registro").reset();
          $('#id').val('');
          setVerBoton(0);

          ListaTabla(`${idmenu}`, 1);

        } else {
          Swal.close();
          Swal.fire(
            'Registro!',
            datosRespuesta.data.msj,
            'error'
          )
        }

      })
      .catch()
  }
  const enviarFactura = async (id) => {
    Swal.showLoading();
    console.log(id);
    let u = localStorage.getItem('idusuario');
    authAxios.post(`exterior/crear-factura-siggo/${id}/${u}`)
      .then((datosRespuesta) => {

        var status = datosRespuesta.data.status;


        Swal.fire(
          'Registro!',
          datosRespuesta.data.msj,
          'success'
        )

        document.getElementById("form_registro").reset();
        $('#id').val('');
        setVerBoton(0);

        ListaTabla(`${idmenu}`, 1);

      })
      .catch()
  }

  const saveProgramacion = async () => {
    Swal.showLoading();
    let form = document.getElementById('form_registro');
    let formData = new FormData(form);
    let u = localStorage.getItem('idusuario');
    authAxios.post(`programacion-mantenimiento/${u}`, formData)
      .then((datosRespuesta) => {

        var status = datosRespuesta.data.status;

        if (datosRespuesta.data) {
          Swal.fire(
            'Registro!',
            datosRespuesta.data.msj,
            'success'
          )

          document.getElementById("form_registro").reset();
          $('#id').val('');
          setVerBoton(0);

          ListaTabla(`${idmenu}`, 1);

        } else {
          Swal.close();
          Swal.fire(
            'Registro!',
            datosRespuesta.data.msj,
            'error'
          )
        }

      })
      .catch()
  }

  const importar = async () => {
    let excel = document.getElementById('excel').value;
    if (excel == '') {
      Swal.fire(
        'Registro!',
        'Debes de subir un docuemnto',
        'error'
      )
      return false;
    }
    Swal.showLoading();
    let form = document.getElementById('subir');
    let formData = new FormData(form);
    authAxios.post(`master/importar/${idmenu}`, formData)
      .then((datosRespuesta) => {

        var status = datosRespuesta.data.status;

        if (datosRespuesta.data.Status) {
          Swal.fire(
            'Registro!',
            datosRespuesta.data.msj,
            'success'
          )
          setVerBoton(0);
          ListaTabla(`${idmenu}`, 1);

        } else {
          Swal.close();
          Swal.fire(
            'Registro!',
            datosRespuesta.data.msj,
            'error'
          )
        }
        document.getElementById("subir").reset();


      })
      .catch()
  }

  const limpiarformulario = () => {
    document.getElementById("form_registro").reset();
    setVerBoton(0);
  }

  const VerRelaciones = (idmenu, iditem) => {
    document.getElementById("item").value = iditem;
    setRelTabla([]);
    setRelLista([]);
    setIsLoading(true);
    // 
    authAxios.get("modulos/listarelaciones/" + NombreTabla)
      .then((datosRespuesta) => {

        var status = datosRespuesta.data.status;

        setRelaciones(datosRespuesta.data.tabla);
        setIsLoading(false);

      })
      .catch();

  }
  const Verimagen = (url) => {
    // 
    window.open(apiImage + url, 'sharer', 'toolbar=0,status=0,width=548,height=325');

  }
  const VerimagenImp = (campo) => {
    // 
    var c = document.getElementById('temp' + campo).value;
    if (c != '')
      window.open(apiImage + c, 'sharer', 'toolbar=0,status=0,width=548,height=325');

  }
  const savecaracol = () => {
    let form = document.getElementById('form_registro');
    let formData = new FormData(form);
    let u = localStorage.getItem('idusuario');
    formData.append('idusuario', u);
    Swal.showLoading();
    authAxios.post("transporte/vehiculo", formData)
      .then((response) => {
        Swal.close();
        // tabla
        if (response.data[0].Confirmacion) {
          Swal.fire({
            title: 'Vehiculo Agregado a caracol con exito',
            icon: 'success',
          });
        } else {
          const errorMessages = response.data[0].ListaMensajes.map(
            (mensaje) => mensaje.Descripcion
          );

          if (errorMessages.length > 1) {
            const tableContent = errorMessages.map((mensaje, index) => (
              `<tr key=${index}><td>${index + 1}</td><td>${mensaje}</td></tr>`
            )).join('');

            const tableHTML = `
                                <div>
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Error</th>
                                    </tr>
                                    </thead>
                                    <tbody>${tableContent}</tbody>
                                </table>
                                </div>
                            `;

            Swal.fire({
              title: 'Se encontraron los siguientes errores:',
              html: tableHTML,
              icon: 'error',
            });
          } else {
            Swal.fire('Conductores', errorMessages[0], 'error');
          }
        }
      })
      .catch()

  }
  const savecaracolconductores = () => {
    let form = document.getElementById('form_registro');
    let formData = new FormData(form);
    let u = localStorage.getItem('idusuario');
    formData.append('idusuario', u);
    Swal.showLoading();
    // Swal.showLoading();
    authAxios.post("transporte/conductor", formData)
      .then((response) => {
        Swal.close();
        if (response.data.Status) {
          if (response.data.resultado_caracol[0].Confirmacion) {
            ListaTabla(`${idmenu}`, 1);
            Swal.fire({
              title: 'Conductor Agregado a caracol con exito',
              icon: 'success',
            });

          } else {
            const errorMessages = response.data.resultado_caracol[0].ListaMensajes.map(
              (mensaje) => mensaje.Descripcion
            );

            if (errorMessages.length > 1) {
              const tableContent = errorMessages.map((mensaje, index) => (
                `<tr key=${index}><td>${index + 1}</td><td>${mensaje}</td></tr>`
              )).join('');

              const tableHTML = `
                          <div>
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Error</th>
                                </tr>
                              </thead>
                              <tbody>${tableContent}</tbody>
                            </table>
                          </div>
                        `;

              Swal.fire({
                title: 'Se encontraron los siguientes errores:',
                html: tableHTML,
                icon: 'error',
              });
            } else {
              Swal.fire('Conductores', errorMessages[0], 'error');
            }
          }
        } else {
          Swal.close();
          Swal.fire(
            'Registro!',
            response.data.msj,
            'error'
          )
        }

      })
      .catch()

  }

  const VerDatos = (tabla, id, pag) => {
    setForanea(tabla);
    setLlave(id);
    setIsLoadingTable(true);
    var item = document.getElementById("item").value;
    setRelacionId(item);
    authAxios.get("master/verrelacion/" + tabla + "/" + id + "/" + item + "?page=" + pag)
      .then((datosRespuesta) => {
        // 
        // 
        var status = datosRespuesta.data.status;
        setIsLoadingTable(false);
        setRelTabla(datosRespuesta.data.modelo);
        setRelLista(datosRespuesta.data.data.data);
        setRelActual(datosRespuesta.data.data.current_page);
        setRelUltimo(datosRespuesta.data.data.last_page);
        // setRelPagina(datosRespuesta.data.data.total);
        setRelTab(datosRespuesta.data.tabla);
        setPestana(datosRespuesta.data.nav);
        setMenu(datosRespuesta.data.modelo[0].id_menu);
        setValor(datosRespuesta.data.traevalue);
        //setInactivarBoton(datosRespuesta.data.editar);

      })
      .catch();
  }
  const CambiarPermiso = (id, campo) => {
    var che = document.getElementById(campo + id);
    var valor = 'No';
    if (che.checked == true) {
      valor = 'Si';
    }
    Swal.showLoading();

    authAxios.get("modulos/registrarcampoitem/" + id + '/' + campo + '/' + valor)
      .then((datosRespuesta) => {
        Swal.close();
        var status = datosRespuesta.data.status;

        Tablas(idmenu);
      })
      .catch();

  }
  //verfactura
  const verfactura = (id) => {
    window.localStorage.setItem('Factura', id);

  }
  const CambiarNombre = (id, campo) => {
    var valor = document.getElementById(campo + id).value;

    if (valor == '') {
      valor = 0;
    }
    authAxios.get("modulos/registrarcampoitem/" + id + '/' + campo + '/' + valor)
      .then((datosRespuesta) => {
        Swal.close();
        var status = datosRespuesta.data.status;

        Tablas(idmenu);
      })
      .catch();

  }
  const Tablas = (id_menu) => {

    authAxios.get("modulos/tablas/" + id_menu)
      .then((datosRespuesta) => {
        //Swal.close();
        var status = datosRespuesta.data.status;


        setConfiguracion(datosRespuesta.data);
      })
      .catch();
    //window.location.href = '/'; window.localStorage.clear();
  }
  const MostrarSelect = async (tabla, id, nombre) => {

    var da = { tabla, id, nombre, NombreTabla }
    authAxios.post("master/relacion", da)
      .then((datosRespuesta) => {
        //Swal.close();
        var status = datosRespuesta.data.status;


        // setTablaSelect(datosRespuesta.data);
      })
      .catch();
  }
  const Buscar = async (tabla, id, nombre, campo) => {
    setIsLoadingOpen(true);
    console.log(tabla, id, nombre, campo);
    setTablaModal([]);
    setInput(campo + "_name");
    setInputId(campo);
    setInputTabla(tabla);
    setInputTablaId(id);
    setCampoSeleccionado(nombre);
    var da = { tabla, id, nombre }
    authAxios.post("master/relacion", da)
      .then((datosRespuesta) => {
        //Swal.close();
        var status = datosRespuesta.data.status;


        setTablaModal(datosRespuesta.data);
        setIsLoadingOpen(false);
      })
      .catch();
  }

  const buscarExpediente = async () => {
    setIsLoadingOpen(true);
    Swal.showLoading();
    authAxios.post("master/relacion/buscar-expediente")
      .then((datosRespuesta) => {
        Swal.close();
        var status = datosRespuesta.data.status;
        console.log(datosRespuesta.data.prefijo);
        const numeroExpediente = document.getElementsByName('numeroExpediente')[0].value = datosRespuesta.data.prefijo + datosRespuesta.data.consecutivo;

        setFormValues((prevValues) => ({
          ...prevValues,
          numeroExpediente: datosRespuesta.data.prefijo + datosRespuesta.data.consecutivo,
        }));

        // setTablaModal(datosRespuesta.data);
        setIsLoadingOpen(false);
      })
      .catch();
  }

  const BuscarIdentificacion = async (tabla, id, nombre, campo) => {
    setIsLoadingOpen(true);
    console.log(tabla, id, nombre, campo);
    setTablaModal([]);
    setInput(campo + "_name");
    setInputId(campo);
    setInputTabla(tabla);
    setInputTablaId(id);
    setCampoSeleccionado(nombre);
    var identificacion = document.getElementById('identificacion_usuario_name').value;
    var da = { tabla, id, nombre, identificacion: identificacion }
    authAxios.post("master/buscar-cliente", da)
      .then((datosRespuesta) => {
        //Swal.close();
        var status = datosRespuesta.data.status;
        var data = datosRespuesta.data.data;
        if (data != null) {
          setFormValues({ ...formValues, nombreUsuario: data.nombre });
          setFormValues({ ...formValues, apellidoUsuario: data.apellido });
          setFormValues({ ...formValues, correoUsuario: data.correo });
          setFormValues({ ...formValues, direccionUsuario: data.direccion });
          setFormValues({ ...formValues, telefonoUsuario: data.telefono });
        }
      })
      .catch();
  }

  const Seleccionar = async (id, nombre, tabla) => {
    console.log(id, nombre, tabla);
    setFormValues((prevValues) => {
      let updatedItems = [...prevValues.items];

      // Si 'id' no es un array, lo agregamos directamente
      if (Array.isArray(id)) {
        updatedItems = [...updatedItems, ...id];
      } else {
        updatedItems.push(id);
      }

      return {
        ...prevValues,
        [tabla]: updatedItems,
        time: '',
      };
    });
    if (tabla == 'categoria_vehiculo') {

      var campos = '';
      if (nombre == 'PRIMEROS' || nombre == 'SEGUNDOS') {
        campos = '.propio-si.hidden';
      }
      else {
        campos = '.contratista-si.hidden';
      }
      // setCategoria(nombre);
      const formElement = document.getElementById('form_registro');
      const divs = formElement.querySelectorAll(campos);

      divs.forEach((div) => {
        console.log(div)
        div.classList.remove('hidden');
      });
    }

    // Asegúrate de que 'Campo' e 'IdCampo' estén definidos y asociados a los elementos correctos
    document.getElementById(Campo).value = nombre;
    document.getElementById(IdCampo).value = id;
  };
  const AddExtra = () => {
    // 
    var extra = document.getElementById('extra').value;
    var da = { tabla: InputTabla, nombre: extra, campo: CampoSeleccionado }
    authAxios.post("master/addextra", da)
      .then((datosRespuesta) => {
        // 
        var status = datosRespuesta.data.status;

        var es = datosRespuesta.data.estado;
        Swal.fire('Registro!', datosRespuesta.data.msj, es == 1 ? 'success' : 'danger');
        Buscar(InputTabla, InputTablaId, CampoSeleccionado, IdCampo);

      })
      .catch();
  }

  const agregaropc = (campo, id, value) => {
    setVistaCampo(campo);
    MostrarSelect(campo, id, value);
    var rel = document.getElementById('vista_relacion').value = campo;


  }
  const AddRelacion = () => {
    var rel = document.getElementById('vista_relacion').value;
    var id = document.getElementById('vista_id_relacion').value;

    if (rel == "") {
      Swal.fire("", "Debes de seleccionar el campo a relacionar", "warning");
      return false;
    }
    if (id == "") {
      Swal.fire("", "Debes de llenar el campo id relacion", "warning");
      return false;
    }
    var da = { tabla: NombreTabla, id: id, campo: rel }
    authAxios.post("master/addvista", da)
      .then((datosRespuesta) => {

        var status = datosRespuesta.data.status;

        var es = datosRespuesta.data.estado;
        Swal.fire('Registro!', datosRespuesta.data.Msj, es == 1 ? 'success' : 'warning');
        MostrarRelacionVista();

      })
      .catch();
  }
  const MostrarRelacionVista = () => {

    var da = { tabla: NombreTabla }
    authAxios.post("master/mostrarvista", da)
      .then((datosRespuesta) => {

        var status = datosRespuesta.data.status;


        // setMostrarRelacion(datosRespuesta.data.datos);


      })
      .catch();
  }
  const MostrarSeleccionados = (id) => {

    var da = { tabla: NombreTabla, id }
    authAxios.post("master/mostrarvistaitem", da)
      .then((datosRespuesta) => {

        var status = datosRespuesta.data.status;


        // setSeleccionados(datosRespuesta.data.datos);


      })
      .catch();
  }
  const RelacionItem = (id) => {
    setRelacionItems(id);
    MostrarSeleccionados(id);
  }
  const AddVistItem = (id) => {
    var da = { tabla: NombreTabla, id: id, VistaCampo: VistaCampo, RelacionItems: RelacionItems }
    authAxios.post("master/addvistaitem", da)
      .then((datosRespuesta) => {

        var status = datosRespuesta.data.status;


        //setMostrarRelacion(datosRespuesta.data.datos);


      })
      .catch();
  }

  const manejoNuevoRegistro = (id) => {
    limpiarformulario();
    if (id == 6) {
      const formElement = document.getElementById('form_registro');
      const divs = formElement.querySelectorAll('div.col-md-4.text-left, div.col-md-6.text-left, div.col-md-8.text-left,div.col-md-12.text-left');

      divs.forEach((div) => {
        const elementId = div.getAttribute('id');

        if (elementId !== 'categoria_vehiculos-div') {
          div.classList.add('hidden');
        }
        else {
          div.classList.remove('hidden');
        }
      });
    }
  };
  // 
  // 
  return (
    <div>
      <div className="content">
        <div className="content-wrapper">
          <div className="content">
            <div className="container-fluid">
              <br />
              <div className="card">

                <div className="card-body">
                  <h4>CREACION DE SERVICIO
                    <br />
                    <div className='text-end'>
                      {Registrar == 1 ?
                        NombreTabla == 'factura' ? <Link className="btn btn-success btn-sm" to={"../pages/Facturacion/Dubai"} onClick={() => verfactura(0)}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Crear Factura</Link> :
                          <button
                            type="button"
                            className="btn btn-success" style={{ marginRight: 10 }}
                            data-bs-toggle='modal' data-bs-target="#FormularioModal"
                            onClick={() => manejoNuevoRegistro(`${idmenu}`)}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Nuevo Registro
                          </button>
                        : ''}

                      {Cargar == 1 ?
                        <button
                          type="button"
                          className="btn btn-info" style={{ marginRight: 10 }}
                          data-bs-toggle='modal' data-bs-target="#ModalExcel"
                          onClick={() => ListaTablaExp(`${idmenu}`)}

                        >
                          <FontAwesomeIcon icon={faDownload} /> Exportar / <FontAwesomeIcon icon={faUpload} /> Importar Datos.
                        </button> : ''}
                      {BtnConfiguracionn == 1 ?

                        <button
                          type="button"
                          className="btn btn-warning"
                          data-bs-toggle="modal" data-bs-target="#exampleModal"
                          onClick={() => Tablas(`${idmenu}`)}
                        >
                          <FontAwesomeIcon icon={faClipboardCheck} /> Configuracion
                        </button> : ''}


                    </div>
                    <input type="hidden" className="form-control" id="tabla" name="tabla" value={NombreTabla} />
                  </h4>
                  {/* <hr /> */}

                  <div className="row table-responsive">


                    <>
                      <h5 className='mt-2 mb-2'>USUARIO A TRANSPORTAR</h5>
                      <div className='col-md-4'>
                        <label>Identificacion</label>
                        <>
                          <input onInput={(e) => { e.target.value = e.target.value.toUpperCase(); }} className={"form-control form-control-sm"} onChange={handleInputChange} type={'hidden'} name={'identificacion_usuario'} id={'identificacion_usuario'} />
                          <div className="input-group mb-3">

                            <input onInput={(e) => { e.target.value = e.target.value.toUpperCase(); }} className={"form-control form-control-sm"} onChange={handleInputChange} type={'number'} name={'identificacion_usuario' + '_name'} id={'identificacion_usuario' + '_name'} />
                            <button className="btn btn-outline-secondary btn-sm" onClick={() => BuscarIdentificacion('tipo_recorrido', 'id', 'nombre', 'tipo_nombre')} type="button"><FontAwesomeIcon icon={faSearch} /></button>
                          </div>
                        </>
                      </div>
                      <div className='col-md-4'>
                        <label>Nombre</label>
                        <input
                          className={"form-control form-control-sm"}
                          type={'text'}
                          name={'nombreUsuario'}
                          value={formValues.nombreUsuario}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='col-md-4'>
                        <label>Apellido</label>
                        <input
                          className={"form-control form-control-sm"}
                          type={'text'}
                          name={'apellidoUsuario'}
                          value={formValues.apellidoUsuario}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='col-md-4'>
                        <label>Telefono</label>
                        <input
                          className={"form-control form-control-sm"}
                          type={'text'}
                          name={'telefonoUsuario'}
                          value={formValues.telefonoUsuario}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='col-md-4'>
                        <label>Correo</label>
                        <input
                          className={"form-control form-control-sm"}
                          type={'text'}
                          name={'correoUsuario'}
                          value={formValues.correoUsuario}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='col-md-4'>
                        <label>Fecha</label>
                        <input
                          className={"form-control form-control-sm"}
                          type={'date'}
                          name={'fecha'}
                          value={formValues.fecha}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='col-md-4'>
                        <label>Hora Inicial</label>
                        <input
                          className={"form-control form-control-sm"}
                          type={'time'}
                          name={'horaInicial'}
                          value={formValues.horaInicial}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='col-md-4'>
                        <label>Direccion Inicial</label>
                        <input
                          className={"form-control form-control-sm"}
                          type={'text'}
                          name={'direccionInicial'}
                          value={formValues.direccionInicial}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='col-md-4'>
                        <label>Direccion Final</label>
                        <input
                          className={"form-control form-control-sm"}
                          type={'text'}
                          name={'direccionFinal'}
                          value={formValues.direccionFinal}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='col-md-12'>
                        <label>Modalida Pago</label>
                        <select className={"form-control form-control-sm"} value={formValues.metodo_pago}
                          onChange={handleInputChange} name={'metodo_pago'} id="" required>
                          <option selected>Selecione una opcion</option>
                          <option value="Efectivo">Efectivo</option>
                          <option value="Transferencia">Transferencia</option>
                          <option value="Cargar a la cuenta">Cargar a la cuenta</option>
                        </select>
                      </div>
                      <div className='col-md-12'>
                        <label>Observaciones</label>
                        <textarea className={"form-control form-control-sm"}
                          name={'obs'}
                          value={formValues.obs}
                          onChange={handleInputChange}></textarea>
                      </div>

                      {/* <h1>Calendario de Servicios</h1> */}
                      <div>

                        {/* <a onClick={handleAddItem} className='btn btn-success mt-4'>Agregar</a> */}



                      </div>
                      <a onClick={saveItem} className='btn btn-info mt-4'>Guardar</a>
                    </>

                  </div>
                  {/* <nav aria-label="Page navigation example">
                    <ul className="pagination">
                      <li className="page-item"><a className="page-link" href="#" onClick={() => ListaTabla(`${idmenu}`, 1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                      <li className="page-item"><a className="page-link" href="#" onClick={() => ListaTabla(`${idmenu}`, actual - 1)}><FontAwesomeIcon icon={faAngleLeft} /></a></li>
                      {(() => {
                        let p = [];
                        p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => ListaTabla(`${idmenu}`, actual)}>{actual}</a></li>);
                        return p;
                      })()}
                      <li className="page-item"><a className="page-link" href="#" onClick={() => ListaTabla(`${idmenu}`, actual + 1)}><FontAwesomeIcon icon={faAngleRight} /></a></li>
                      <li className="page-item"><a className="page-link" href="#" onClick={() => ListaTabla(`${idmenu}`, ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                    </ul>
                  </nav> */}
                </div>
                {/* <div className="card-footer text-muted">
                  <b>Pagina</b> <span className="badge bg-primary">{actual}</span> <b>de</b> <span className="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span className="badge bg-success">{pagina}</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="FormularioModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
        <div className={"modal-dialog  modal-" + Tamano} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="permisoRolesModalLabel">Formulario de registro {Titulo}</h4>

              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form id="form_registro">
                {/* <div> */}
                <div className="card-body">

                  <ul className="nav nav-tabs" id="icon-tab" role="tablist">
                    {
                      Tabs.map((row, i) => (
                        <li key={i} className="nav-item"><a className={i == 0 ? "nav-link active" : "nav-link"} id="icon-home-tab" data-bs-toggle="tab" href={"#icon-" + row.pestana} role="tab" aria-controls={"icon-" + row.pestana} aria-selected="true"><i className={i == 0 ? 'icofont icofont-ui-home' : 'icofont icofont-files'}></i>{row.pestana == null ? 'DATOS_BASICOS' : row.pestana}</a></li>

                      ))
                    }
                    {
                      Titulo == 'Vehiculos' ? (
                        <>
                          <li className="nav-item"><a className={'nav-link'} id="icon-home-tab" data-bs-toggle="tab" href={"#icon-programacion"} role="tab" aria-controls={"icon-programacion"} aria-selected="true" onClick={() => verProgramacionVh(Detalles.id)}><i className={'icofont'}></i>MANTENIMIENTOS</a></li>
                          <li className="nav-item"><a className={'nav-link'} id="icon-home-tab" data-bs-toggle="tab" href={"#icon-ejecucion-registrar"} role="tab" aria-controls={"icon-ejecucion-registrar"} aria-selected="true"><i className={'icofont'}></i>REGISTRAR EJECUCION</a></li>
                        </>
                      ) : ''
                    }
                    {
                      NombreTabla == 'servicio' ? (
                        <>
                          <li className="nav-item"><a className={'nav-link'} id="icon-home-tab" data-bs-toggle="tab" href={"#icon-clientacion"} role="tab" aria-controls={"icon-clientacion"} aria-selected="true" onClick={() => verProgramacionVh(Detalles.id)}><i className={'icofont'}></i>USUARIO</a></li>

                        </>
                      ) : ''
                    }
                  </ul>

                  <div className="tab-content" id="icon-tabContent" key={1}>
                    <>
                      <div className='col-md-4'>
                        <label>Cliente</label>
                        <>
                          <input onInput={(e) => { e.target.value = e.target.value.toUpperCase(); }} className={"form-control form-control-sm"} readOnly={'readOnly'} type={'hidden'} name={'nombre_clientes'} onChange={handleInputChange} id={'nombre_clientes'} />
                          <div className="input-group mb-3">

                            <input onInput={(e) => { e.target.value = e.target.value.toUpperCase(); }} className={"form-control form-control-sm"} readOnly={'readOnly'} type={'text'} name={'nombre_clientes' + '_name'} onChange={handleInputChange} id={'nombre_clientes' + '_name'} />
                            <button className="btn btn-outline-secondary btn-sm" onClick={() => Buscar('clientes', 'id', 'nombres', 'nombre_clientes')} type="button" data-bs-toggle='modal' data-bs-target="#ModalSelect"><FontAwesomeIcon icon={faSearch} /></button>
                          </div>
                        </>
                      </div>
                      <div className='col-md-4'>
                        <label>Ciudad</label>
                        <>
                          <input onInput={(e) => { e.target.value = e.target.value.toUpperCase(); }} className={"form-control form-control-sm"} readOnly={'readOnly'} type={'hidden'} onChange={handleInputChange} name={'ciudad_nombre'} id={'ciudad_nombre'} />
                          <div className="input-group mb-3">

                            <input onInput={(e) => { e.target.value = e.target.value.toUpperCase(); }} className={"form-control form-control-sm"} readOnly={'readOnly'} type={'text'} onChange={handleInputChange} name={'ciudad_nombre' + '_name'} id={'ciudad_nombre' + '_name'} />
                            <button className="btn btn-outline-secondary btn-sm" onClick={() => Buscar('ciudad', 'id', 'nombre', 'ciudad_nombre')} type="button" data-bs-toggle='modal' data-bs-target="#ModalSelect"><FontAwesomeIcon icon={faSearch} /></button>
                          </div>
                        </>
                      </div>
                      <div className='col-md-4'>
                        <label>Estado de Servicio</label>
                        <>
                          <input onInput={(e) => { e.target.value = e.target.value.toUpperCase(); }} className={"form-control form-control-sm"} readOnly={'readOnly'} onChange={handleInputChange} type={'hidden'} name={'estado_nombre'} id={'estado_nombre'} />
                          <div className="input-group mb-3">

                            <input onInput={(e) => { e.target.value = e.target.value.toUpperCase(); }} className={"form-control form-control-sm"} readOnly={'readOnly'} onChange={handleInputChange} type={'text'} name={'estado_nombre' + '_name'} id={'estado_nombre' + '_name'} />
                            <button className="btn btn-outline-secondary btn-sm" onClick={() => Buscar('estado_servicio', 'id', 'estado', 'estado_nombre')} type="button" data-bs-toggle='modal' data-bs-target="#ModalSelect"><FontAwesomeIcon icon={faSearch} /></button>
                          </div>
                        </>
                      </div>
                      <div className='col-md-4'>
                        <label>Tarifa</label>
                        <>
                          <input onInput={(e) => { e.target.value = e.target.value.toUpperCase(); }} className={"form-control form-control-sm"} readOnly={'readOnly'} onChange={handleInputChange} type={'hidden'} name={'modalidad_nombre'} id={'modalidad_nombre'} />
                          <div className="input-group mb-3">

                            <input onInput={(e) => { e.target.value = e.target.value.toUpperCase(); }} className={"form-control form-control-sm"} readOnly={'readOnly'} onChange={handleInputChange} type={'text'} name={'modalidad_nombre' + '_name'} id={'modalidad_nombre' + '_name'} />
                            <button className="btn btn-outline-secondary btn-sm" onClick={() => Buscar('modalidad_tari', 'id', 'nombre', 'modalidad_nombre')} type="button" data-bs-toggle='modal' data-bs-target="#ModalSelect"><FontAwesomeIcon icon={faSearch} /></button>
                          </div>
                        </>
                      </div>
                      <div className='col-md-4'>
                        <label>Tipo Recorrido</label>
                        <>
                          <input onInput={(e) => { e.target.value = e.target.value.toUpperCase(); }} className={"form-control form-control-sm"} readOnly={'readOnly'} onChange={handleInputChange} type={'hidden'} name={'tipo_nombre'} id={'tipo_nombre'} />
                          <div className="input-group mb-3">

                            <input onInput={(e) => { e.target.value = e.target.value.toUpperCase(); }} className={"form-control form-control-sm"} readOnly={'readOnly'} onChange={handleInputChange} type={'text'} name={'tipo_nombre_name'} id={'tipo_nombre_name'} />
                            <button className="btn btn-outline-secondary btn-sm" onClick={() => Buscar('tipo_recorrido', 'id', 'nombre', 'tipo_nombre')} type="button" data-bs-toggle='modal' data-bs-target="#ModalSelect"><FontAwesomeIcon icon={faSearch} /></button>
                          </div>
                        </>
                      </div>
                      <div className='col-md-4'>
                        <label>Contacto Logistica</label>
                        <input
                          className={"form-control form-control-sm"}
                          type={'text'}
                          name={'numeroContacto'}
                          value={formValues.numeroContacto}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='col-md-4'>
                        <label>Nombre Logistica</label>
                        <input
                          className={"form-control form-control-sm"}
                          type={'text'}
                          name={'nombreLogistica'}
                          value={formValues.nombreLogistica}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='col-md-4'>
                        <label>Cargo Logistica</label>
                        <input
                          className={"form-control form-control-sm"}
                          type={'text'}
                          name={'cargoLogistica'}
                          value={formValues.cargoLogistica}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='col-md-4'>
                        <label>Numero Expediente</label>
                        <div className="input-group mb-3">
                          <input
                            className={"form-control form-control-sm"}
                            type={'text'}
                            name={'numeroExpediente'}
                            value={formValues.numeroExpediente}
                            onChange={handleInputChange}
                          />

                          <button className="btn btn-outline-secondary btn-sm" onClick={() => buscarExpediente()} type="button" ><FontAwesomeIcon icon={faSearch} /></button>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <label>Fecha</label>
                        <input
                          className={"form-control form-control-sm"}
                          type={'date'}
                          name={'fecha'}
                          value={formValues.fecha}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='col-md-4'>
                        <label>Hora Inicial</label>
                        <input
                          className={"form-control form-control-sm"}
                          type={'time'}
                          name={'horaInicial'}
                          value={formValues.horaInicial}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='col-md-4'>
                        <label>Direccion Inicial</label>
                        <input
                          className={"form-control form-control-sm"}
                          type={'text'}
                          name={'direccionInicial'}
                          value={formValues.direccionInicial}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='col-md-4'>
                        <label>Direccion Final</label>
                        <input
                          className={"form-control form-control-sm"}
                          type={'text'}
                          name={'direccionFinal'}
                          value={formValues.direccionFinal}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='col-md-4'>
                        <label>Cantidad</label>
                        <input
                          className={"form-control form-control-sm"}
                          type={'number'}
                          name={'cantidad'}
                          value={formValues.cantidad}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='col-md-12'>
                        <label>Observaciones</label>
                        <textarea className={"form-control form-control-sm"}
                          name={'obs'}
                          value={formValues.obs}
                          onChange={handleInputChange}></textarea>
                      </div>

                      <h1>Calendario de Servicios</h1>
                      <div>
                        <DatePicker
                          selected={null}
                          onChange={handleDateChange}
                          inline
                        />
                        <br />

                        <a onClick={handleAddItem} className='btn btn-success mt-4'>Agregar</a>
                        <table className='table table-bordered'>
                          <tr>
                            {/* <th>Id Servicio</th> */}
                            <th>Fecha</th>
                            <th>Hora</th>
                            <th>Destino</th>
                          </tr>
                          {items.map((item, index) => (
                            <tr key={index}>
                              <td>{item.date}</td>
                              <td>
                                <input
                                  className={"form-control form-control-sm"}
                                  type="time"
                                  name={`itemsa${index}hora`}  // Nombre dinámico
                                  onChange={handleInputChange}
                                />
                              </td>
                              <td>
                                <div className='col-md-12'>
                                  <label>Direccion Inicial</label>
                                  <input
                                    className={"form-control form-control-sm"}
                                    type={'text'}
                                    name={`itemsa${index}direccionInicial`}  // Nombre dinámico
                                    onChange={handleInputChange}
                                  />
                                </div>
                                <div className='col-md-12'>
                                  <label>Direccion Final</label>
                                  <input
                                    className={"form-control form-control-sm"}
                                    type={'text'}
                                    name={`itemsa${index}direccionFinal`}  // Nombre dinámico
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </table>


                      </div>
                      <a onClick={saveItem} className='btn btn-info mt-4'>Guardar</a>
                    </>
                  </div>
                </div>


                {
                  NombreTabla != 'mantenimientos' ? (
                    <div className="modal-footer">
                      {NombreTabla != 'servicio' ? (
                        <button type="button" className="btn btn-primary btn-sm" disabled={InactivarBoton == 0 ? false : true} onClick={() => save()}>Guardar Registro.</button>
                      ) : ''}

                      <button type="button" className="btn btn-danger btn-sm" data-bs-dismiss="modal">Cerrar</button>
                      {NombreTabla == "vehiculo" ? <button type="button" className="btn btn-success btn-sm" onClick={() => savecaracol()}>Guardar en caracol</button> : ''}
                      {NombreTabla == "conductores" ? <button type="button" className="btn btn-success btn-sm" onClick={() => savecaracolconductores()}>Guardar en caracol</button> : ''}
                      {NombreTabla == "fuec" ? Boton == '1' ? <button type="button" className="btn btn-secondary btn-sm" data-bs-toggle='modal' data-bs-target="#ModalImprimir" > <FontAwesomeIcon icon={faPrint} /> Imprimir FUEC</button> : '' : ''}
                    </div>
                  ) : (
                    <div className="modal-footer">

                      <button type="button" className="btn btn-primary btn-sm" onClick={() => saveProgramacion()}>Guardar Registro.</button>
                      <button type="button" className="btn btn-danger btn-sm" data-bs-dismiss="modal">Cerrar</button>
                    </div>
                  )
                }


                {/* </div> */}

              </form>
            </div>
          </div>
        </div>

      </div>

      <div className="modal fade" id="ModalRelacion" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
        <div className={"modal-dialog  modal-" + Tamano} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="permisoRolesModalLabel">Tablas Relacionadas</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              <input type="hidden" id="item" name="item" />
            </div>
            <div className="modal-body">
              <form id="form_registro">
                <div>
                  <div className="card-body">
                    <div className="card-body btn-showcase">
                      <h5 className='text-center'>¡Que quieres Consultar?</h5>
                      {isLoading ? (
                        // Mostrar el preloader mientras isLoading sea true
                        <div className="text-center">
                          <p>Cargando...</p>
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Cargando...</span>
                          </div>
                        </div>
                      ) : (

                        Relaciones.map((row, i) => (

                          <button key={i} className="btn btn-secondary btn-sm" onClick={() => VerDatos(row.tablaforanea, row.relacion_fk, 1)} type="button" title="" data-bs-original-title="btn btn-primary btn-xs" data-original-title="btn btn-primary btn-xs">{row.nombre_tabla} </button>

                        ))

                      )}

                      < br />

                      <ul className="nav nav-tabs" style={{ marginTop: 10 }}>
                        <li className="nav-item">
                          <a className="nav-link active" data-bs-toggle="tab" href="#home">Listado</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" data-bs-toggle="tab" href="#menu1">Crear Registro</a>
                        </li>
                      </ul>
                      <div className="tab-content" style={{ marginBottom: 10 }}>
                        <div className="tab-pane container active" id="home">
                          <div id="buscador2" className='table-responsive'>
                            <table className="table table-hover table-condensed  table-xs">
                              <thead className="thead-inverse">
                                <tr>
                                  {isLoadingTable ? (
                                    // Mostrar el preloader mientras isLoading sea true
                                    <div className="text-center">
                                      <p>Cargando...</p>
                                      <div className="spinner-border" role="status">
                                        <span className="sr-only">Cargando...</span>
                                      </div>
                                    </div>
                                  ) : (
                                    Rel_Tabla.map((row) => (

                                      row.mostrar_consulta == 'Si' ?
                                        <th className="align-middle" key={row.id}>{row.nombre_titulo}</th> : ''


                                    ))
                                  )}
                                  <th> Opciones</th>
                                </tr>



                              </thead>

                              <tbody>



                                {
                                  Rel_ItemTabla.map((ITEM, i) => (
                                    <tr key={i}>
                                      {
                                        Rel_Tabla.map((row, j) => (
                                          row.mostrar_consulta == 'Si' ?
                                            <td key={row.id} className={row.notifica == 1 ? ITEM[row.nombre_campo] > hoy ? 'bg-success' : 'bg-danger' : ''}>

                                              {row.tipo_dato == 'blob' ?
                                                <FontAwesomeIcon icon={faImage} onClick={() => Verimagen(ITEM[row.nombre_campo])} />
                                                : ITEM[row.nombre_campo]}
                                            </td> : ''
                                        ))
                                      }
                                      <td>
                                        <button type="button" className='btn btn-secondary btn-xs' onClick={() => BorrarRelacion(`${Foranea}`, `${Llave}`, ITEM['id'])}> <FontAwesomeIcon icon={faTrashAlt} /> </button>
                                      </td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </table></div>
                          <nav aria-label="Page navigation example">
                            <ul className="pagination">
                              <li className="page-item"><a className="page-link" href="#" onClick={() => VerDatos(`${Foranea}`, `${Llave}`, 1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                              <li className="page-item"><a className="page-link" href="#" onClick={() => VerDatos(`${Foranea}`, `${Llave}`, Rel_actual - 1)}><FontAwesomeIcon icon={faAngleLeft} /></a></li>
                              {(() => {
                                let pr = [];
                                pr.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => VerDatos(`${Foranea}`, `${Llave}`, Rel_actual)}>{Rel_actual}</a></li>);
                                return pr;
                              })()}
                              <li className="page-item"><a className="page-link" href="#" onClick={() => VerDatos(`${Foranea}`, `${Llave}`, Rel_actual + 1)}><FontAwesomeIcon icon={faAngleRight} /></a></li>
                              <li className="page-item"><a className="page-link" href="#" onClick={() => VerDatos(`${Foranea}`, `${Llave}`, Rel_ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                            </ul>
                          </nav>
                          {/* {Foranea} - {Llave} */}
                        </div>
                        <div className="tab-pane container fade" id="menu1">
                          <FormularioCrear actualizarRelaciones={actualizarRelaciones} dato={TabsRel} tabla={Rel_Tabla} pesta={Rel_nav} menu={Menu} llave={Llave} traevalor={TraeValue} relacionid={RelacionId} tablaprimaria={NombreTabla} />
                        </div>
                      </div>




                    </div>


                  </div>
                  <div className="modal-footer">

                    <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                  </div>

                </div>

              </form>
            </div>
          </div>
        </div>

      </div >

      <div className="modal fade" id="FormularioModalDetalle" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
        <div className={"modal-dialog  modal-" + Tamano} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="permisoRolesModalLabel">Informe Detallado</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form id="form_registro">
                <div>
                  <div className="card-body">
                    <ul className="nav nav-tabs" id="icon-tabd" role="tablist">
                      {
                        Tabs.map((row, i) => (

                          <li key={i} className="nav-item"><a className={i == 0 ? "nav-link active" : "nav-link"} id="icon-homed-tab" data-bs-toggle="tab" href={"#icon-d" + row.pestana} role="tab" aria-controls={"icon-d" + row.pestana} aria-selected="true"><i className={i == 0 ? 'icofont icofont-ui-home' : 'icofont icofont-files'}></i>{row.pestana}</a></li>

                        ))
                      }
                    </ul>

                    <div className="tab-content" id="icon-tabContent">
                      {
                        Tabs.map((fila, i) => (
                          <div className={i == 0 ? "tab-pane fade show active" : "tab-pane fade"} id={"icon-d" + fila.pestana} role="tabpanel" aria-labelledby={"icon-d" + fila.pestana + "-tab"}>

                            <div className="card-body">
                              <div className="row">
                                <div className="card">
                                  <div className="row card-body">
                                    {
                                      Tabla.map((row, i) => (
                                        fila.pestana == row.pestana ?
                                          <div className="col-md-4" key={i}>

                                            {row.tipo_dato == 'blob' ?

                                              <div className="gallery my-gallery card-body row" itemscope="">
                                                <figure className="col-xl-12 col-md-12 col-12" itemprop="associatedMedia" itemscope="">
                                                  <a href="#" itemprop="contentUrl" data-size="1600x950"  >


                                                    <iframe className="img-thumbnail" id={'imgd' + row.nombre_campo} src="https://nosso.com/public/images/tipoproducto/default.jpg" itemprop="thumbnail" alt="..."></iframe>

                                                  </a>
                                                  <figcaption itemprop="caption description">{row.nombre_titulo} <button type='button' className='btn btn-success btn-xs' onClick={() => VerimagenImp(row.nombre_campo)} >Ver</button></figcaption>
                                                  <input className={"form-control form-control-sm"} type="hidden" name={'temp' + row.nombre_campo} id={'temp' + row.nombre_campo} disabled readonly={true} />
                                                </figure>
                                              </div>
                                              : <label htmlFor="cedula">{row.nombre_titulo} {row.obligatorio == '1' ? <span className="text-danger">*</span> : ''}</label>}

                                            {

                                              row.trae_tabla === null ?
                                                row.tipo_dato == 'text' ?
                                                  <textarea className={"form-control form-control-sm"} readOnly={row.campo_editable == 'Si' ? '' : 'readOnly'} name={'det' + row.nombre_campo} id={'det' + row.nombre_campo}></textarea> :
                                                  <input className={"form-control form-control-sm"} readOnly={i != 0 ? false : true} type={row.tipo_dato == 'int' ? 'number' : row.tipo_dato == 'date' ? 'date' : row.tipo_dato == 'time' ? 'time' : row.tipo_dato == 'blob' ? 'file' : 'text'} name={'det' + row.nombre_campo} id={'det' + row.nombre_campo} disabled readonly={true} />
                                                :
                                                row.trae_tabla == '' ?
                                                  <input className={"form-control form-control-sm"} readOnly={i != 0 ? false : true} type={row.tipo_dato == 'int' ? 'number' : row.tipo_dato == 'date' ? 'date' : row.tipo_dato == 'time' ? 'time' : row.tipo_dato == 'blob' ? 'file' : 'text'} name={'det' + row.nombre_campo} id={'det' + row.nombre_campo} disabled readonly={true} />
                                                  :
                                                  ''
                                              // <Option t={row.trae_tabla} v={row.trae_value} c={row.trae_campo} nombre_input={'det' + row.nombre_campo} depende={row.depende_campo ? row.depende_campo : 0} tablaprimaria={row.nombre_tabla} />


                                            }
                                            {
                                              NombreTabla == "factura_i" ?
                                                row.nombre_campo == 'id_siigo' ? <>
                                                  <a className='btn btn-primary mt-4' onClick={() => fetchPdf(Detalles.id_siigo)}>Ver PDF de la factura</a>
                                                </> : ''
                                                : ''
                                            }

                                          </div> : ''

                                      ))
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                      <div className="tab-pane fade p-4" id="icon-delementos-decontrol" role="tabpanel" aria-labelledby={"icon-delementos-decontrol-tab"}>
                        <table className="table table-hover table-condensed table-sm">
                          <thead className="thead-inverse">
                            <tr>
                              <th className="align-middle">TIPO DOCUMENTO</th>
                              <th className="align-middle">FECHA DE VENCIMIENTO</th>
                              <th className="align-middle">FECHA DE REGISTRO</th>
                              <th className="align-middle">ESTADO</th>
                              <th className="align-middle">ARCHIVO</th>
                            </tr>
                          </thead>
                          <tbody>
                            {elementosControl !== null ? (
                              elementosControl.map((elemento) => (
                                <tr key={elemento.id}>
                                  <td>{elemento.nombre}</td>
                                  <td>{elemento.fecha_vencimiento}</td>
                                  <td>{elemento.fecha_registro}</td>
                                  <td style={{ color: elemento.estado === 2 ? 'red' : 'green' }}>
                                    {elemento.estado === 2 ? 'INACTIVO' : 'ACTIVO'}
                                  </td>
                                  <td>
                                    <FontAwesomeIcon icon={faImage} onClick={() => Verimagen(elemento.archivo)} />
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={5}>No hay elementos disponibles</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>





                  <div className="modal-footer">
                    {NombreTabla == "fuec" ? Boton == '1' ? <button type="button" className="btn btn-secondary btn-sm" data-bs-toggle='modal' data-bs-target="#ModalImprimir" > <FontAwesomeIcon icon={faPrint} /> Imprimir FUEC</button> : '' : ''}

                    <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                  </div>

                </div>

              </form>
            </div>
          </div>
        </div>

      </div>

      <div className="modal fade" id="FormularioCambioClaveProveedor" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
        <div className={"modal-dialog  modal-" + Tamano} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="permisoRolesModalLabel">Asignacion Contraseña</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form id="form_registro_password">
                <div>
                  <div className="card-body mb-4">
                    <label>Contraseña</label>
                    <input className={"form-control form-control-sm"} type='password' name='password' id='password' />
                    <label>Repetir Contraseña</label>
                    <input className={"form-control form-control-sm"} type='password' name='repassword' id='repassword' />

                  </div>





                  <div className="modal-footer">
                    <button type="button" className="btn btn-success btn-sm" onClick={() =>
                      guardarClaveProveedor()
                    }>Guardar</button>
                    <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                  </div>

                </div>

              </form>
            </div>
          </div>
        </div>

      </div>
      <div className="modal fade" id="FormularioActualizacionKm" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
        <div className={"modal-dialog  modal-" + Tamano} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="permisoRolesModalLabel">Actualizar Odometro</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form id="form_registro_password">
                <div>
                  <div className="card-body mb-4">
                    <label>Kilometraje Actual</label>
                    <input className={"form-control form-control-sm"} type='text' disabled name='password' id='kmactualver' />
                    <label>Kilometraje Nuevo</label>
                    <input className={"form-control form-control-sm"} type='number' name='repassword' id='kmnuevoact' />
                    <input hidden className={"form-control form-control-sm"} type='number' name='repassword' id='kmactualverinputid' />
                  </div>





                  <div className="modal-footer">
                    <button type="button" className="btn btn-success btn-sm" onClick={() =>
                      actualizarkmvehiculo()
                    }>Actualizar</button>
                    <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                  </div>

                </div>

              </form>
            </div>
          </div>
        </div>

      </div>
      <div className="modal fade" id="ModalExcel" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
        <div className={"modal-dialog  modal-lg"} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="permisoRolesModalLabel">Opciones de Exportar/Importar</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="">
                <div>
                  <div className="card-body">
                    <form id="subir">
                      <div className="row">
                        <div className="col-md-6">
                          <label htmlFor="identificacion">Puedes subir el excel en formato .csv</label>
                          <input className="form-control form-control-sm" type="file" name="excel" id="excel" placeholder="Numero" />

                        </div>
                        <div className="col-md-6">
                          <label htmlFor="identificacion">Clic para subir el archivo</label><br></br>
                          <button type="button" className="btn btn-secondary btn-sm" onClick={() => importar()}><FontAwesomeIcon icon={faUpload} /> Subir Archivo</button>
                        </div>
                        <div className="col-md-6">
                          <label>Debe identificar las columnas con * que indican que es un campo obligatorio, y si la columna tiene "FK" esto quiere decir que depende de un listado que esta en la segunda hoja de la plantilla de ejemplo, selecionando el numero "IDENTIFICADOR".</label>
                          <img className="img-fluid for-light" src={require("../../assets/images/ayuda1.png")} alt="" />
                        </div>
                        <div className="col-md-6">
                          <label>En la segunda hoja noS mostrara el listado de las relaciones de las columnas con FK. </label>
                          <img className="img-fluid for-light" src={require("../../assets/images/ayuda2.png")} alt="" />
                        </div>
                      </div>
                    </form>
                  </div>
                  <br />
                  <div className="modal-footer">
                    <DownloadTableExcel
                      filename="ListaRegistro"
                      sheet="Listado"
                      currentTableRef={tableRef.current}>
                      {botondescargar}

                    </DownloadTableExcel>
                    <button type="button" className="btn btn-primary btn-sm" onClick={() => DescargarExcel()}><FontAwesomeIcon icon={faDownload} /> Descargar Plantilla Ejemplo</button>
                    <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="modal fade" id="ModalExcelPlanilla" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
        <div className={"modal-dialog  modal-" + Tamano} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="permisoRolesModalLabel">Opciones de descarga</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form id="form_registroe">
                <div>
                  <div className="card-body">
                    <table className="table table-hover table-condensed table-xs" ref={tableRef}>
                      <thead className="thead-inverse">
                        <tr>
                          {
                            TablaExp.map((row) => (
                              <th className="align-middle" key={row.id}>{row.nombre_titulo}</th>
                            ))
                          }

                        </tr>


                      </thead>

                      <tbody>
                        {
                          ItemTablaExp.map((ITEM, i) => (
                            <tr key={i}>
                              {
                                TablaExp.map((row, j) => (

                                  <td key={row.id} className={row.notifica == 1 ? ITEM[row.nombre_campo] > hoy ? 'bg-success' : 'bg-danger' : ''}>

                                    {row.tipo_dato == 'blob' ?
                                      ITEM[row.nombre_campo] != null ? <FontAwesomeIcon icon={faImage} onClick={() => Verimagen(ITEM[row.nombre_campo])} /> : ''
                                      : ITEM[row.nombre_campo]}
                                  </td>
                                ))
                              }

                            </tr>
                          ))
                        }
                      </tbody>
                    </table>

                    <table className="table table-hover table-condensed table-xs" ref={tableHeader}>
                      <thead className="thead-inverse">
                        <tr>
                          {
                            Tabla.map((row) => (
                              <th className="align-middle" key={row.id}>{row.nombre_titulo} {row.tipo_dato == 'date' ? '2022-01-01' : ''}</th>
                            ))
                          }

                        </tr>


                      </thead>


                    </table>
                  </div>
                  <div className="modal-footer">

                    <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                  </div>

                </div>

              </form>
            </div>
          </div>
        </div>

      </div>

      <div className="modal fade" id="ModalImprimir" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
        <div className={"modal-dialog  modal-" + Tamano} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="permisoRolesModalLabel">Imprimir</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">

            </div>
          </div>
        </div>

      </div>
      <div className="modal fade" id="ModalImprimirDetalle" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
        <div className={"modal-dialog  modal-" + Tamano} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="permisoRolesModalLabel">Imprimir Registro</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">

            </div>
          </div>
        </div>

      </div>

      <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Configuración del modulo</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <div className="card">
                <div className="card-body">

                  <div className="row g-3 table-responsive">
                    <table className="table table-hover table-condensed table-sm">
                      <thead className="thead-inverse">
                        <tr>

                          <th className="align-middle">CAMPO</th>
                          <th className="align-middle">PESTAÑA</th>
                          <th className="align-middle">ORDEN</th>
                          <th className="align-middle">COLUMNA</th>
                          <th className="align-middle">ALINEACION</th>
                          <th className="align-middle">LISTA</th>
                          <th className="align-middle">ORDENA</th>
                          <th className="align-middle">EDITA</th>
                          <th className="align-middle">PROPIO</th>
                          <th className="align-middle">CONTRATISTA</th>
                          <th className="align-middle">OBLIGA CARACOL</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          Configuracion.map((item) => (
                            <tr key={item.id}>
                              <td><input type="text" id={"nombre_titulo" + item.id} defaultValue={item.nombre_titulo} className={"form-control form-control-sm"} onChange={() => CambiarNombre(item.id, 'nombre_titulo')} style={{ width: "200px" }} /></td>

                              <td> <input type="text" id={"pestana" + item.id} defaultValue={item.pestana} className={"form-control form-control-sm"} onChange={() => CambiarNombre(item.id, 'pestana')} /></td>
                              <td> <input type="text" id={"orden" + item.id} defaultValue={item.orden} className={"form-control form-control-sm"} onChange={() => CambiarNombre(item.id, 'orden')} /></td>
                              <td>
                                <select id={"columna" + item.id} value={item.columna} options={item.columna} className="form-control form-control-sm" onChange={() => CambiarNombre(item.id, 'columna')}>
                                  <option value="4">4</option>
                                  <option value="6">6</option>
                                  <option value="8">8</option>
                                  <option value="12">12</option>
                                </select>
                              </td>
                              <td>
                                <select id={"alineacion" + item.id} defaultValue={item.alineacion} options={item.alineacion} className="form-control form-control-sm" onChange={() => CambiarNombre(item.id, 'alineacion')}>
                                  <option value="text-left">text-left</option>
                                  <option value="text-center">text-center</option>
                                  <option value="text-right">text-right</option>

                                </select>
                              </td>
                              <td><input type="checkbox" id={"mostrar_consulta" + item.id} checked={item.mostrar_consulta == 'Si' ? true : false} onClick={() => CambiarPermiso(item.id, 'mostrar_consulta')} /></td>
                              <td><input type="checkbox" id={"orderby" + item.id} checked={item.orderby == 'Si' ? true : false} onClick={() => CambiarPermiso(item.id, 'orderby')} /></td>
                              <td><input type="checkbox" id={"campo_editable" + item.id} checked={item.campo_editable == 'Si' ? true : false} onClick={() => CambiarPermiso(item.id, 'campo_editable')} /></td>
                              <td><input type="checkbox" id={"campo_propio" + item.id} checked={item.campo_propio == 'Si' ? true : false} onClick={() => CambiarPermiso(item.id, 'campo_propio')} /></td>
                              <td><input type="checkbox" id={"campo_contratista" + item.id} checked={item.campo_contratista == 'Si' ? true : false} onClick={() => CambiarPermiso(item.id, 'campo_contratista')} /></td>
                              <td><input type="checkbox" id={"obligatorio_caracol" + item.id} checked={item.obligatorio_caracol == 'Si' ? true : false} onClick={() => CambiarPermiso(item.id, 'obligatorio_caracol')} /></td>

                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>


              </div>
            </div>
            <div className="modal-footer">

              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>

            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="ModalSelect" tabIndex={-1} aria-labelledby="ModalSelectLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content bg-dark bg-gradient " >
            <div className="modal-header">
              <h5 className="modal-title" id="ModalSelectLabel">Seleccione </h5>

              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <div>
                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Listado</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">+ Registro</button>
                  </li>

                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div className="card">
                      <div className="card-body">

                        <div className="row g-3 table-responsive" id="f1">
                          {/* <input
                            type="text"
                            placeholder="Buscar..."
                            className="form-control mb-3"
                            value={setSearchTermsSec}
                            onChange={(e) => setSearchTermsSec(e.target.value)} // Actualizar el término de búsqueda
                          /> */}
                          <table className="table table-hover table-condensed table-sm">
                            <thead className="thead-inverse">
                              <tr>

                                <th className="align-middle">ID</th>
                                <th className="align-middle">DESCRIPCION</th>
                              </tr>
                            </thead>
                            <tbody>
                              {isLoadingOpen ? (
                                // Mostrar el preloader mientras isLoading sea true
                                <div className="text-center">
                                  <p>Cargando...</p>
                                  <div className="spinner-border" role="status">
                                    <span className="sr-only">Cargando...</span>
                                  </div>
                                </div>
                              ) : (

                                TablaModal.map((item) => (
                                  <tr key={item.id}>
                                    <td>{item.id}</td>

                                    <td><a onClick={() => Seleccionar(item.id, item.nombre, item.tabla)} data-bs-dismiss="modal"><ins>{item.nombre}</ins></a></td>


                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>


                    </div>
                  </div>
                  <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div className="row" id="f1">
                      <h4>Registro</h4>
                      <div>
                        <label>Nombre</label>
                        <input type="text" className="form-control" id="extra" name="extra" />
                      </div>
                      <div className="mb-3">
                        <br></br>
                        <input type="button" className="btn btn-primary" id="extra_btn" value={'Agregar'} onClick={() => AddExtra()} />
                      </div>
                    </div>
                  </div>

                </div>
              </div>



            </div>
            <div className="modal-footer">

              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>

            </div>
          </div>
        </div>
      </div>

    </div >
  )
}

export default Index;