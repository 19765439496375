import React, { useState, useEffect } from 'react';
import man from '../assets/images/dashboard/profile.jpg';
import { Container, Row, Col, Form, Input, Label, Button, TabContent, TabPane } from 'reactstrap'
import { Jwt_token } from '../data/config'
import { useAuth0 } from '@auth0/auth0-react'
import {AUTH0 } from '../constant';
import { classes } from '../data/layouts';
import axios from 'axios';
import Swal from 'sweetalert2';
import Api from "../services/Api";

const Logins = (props) => {

  const { loginWithRedirect } = useAuth0()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selected, setSelected] = useState("jwt");
  const [togglePassword, setTogglePassword] = useState(false);
  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

  const [value, setValue] = useState(
    localStorage.getItem('profileURL' || man)
  );
  const [name, setName] = useState(
    localStorage.getItem('Name')
  );

  const [Latitude, setLatitude] = useState("");
  const [Longitude, setLongitude] = useState("");


  useEffect(() => {

    localStorage.setItem('profileURL', value);
    localStorage.setItem('Name', name);
    navigator.geolocation.getCurrentPosition(function (position) {

      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
      localStorage.setItem('Latitude', position.coords.latitude);
      localStorage.setItem('Longitude', position.coords.longitude);
    });

  }, [value, name]);



  const sesion = () => {

    var datosEnviar = { email: email, password: password, latitude: Latitude, longitude: Longitude }
    Swal.showLoading();
    axios
      .post(Api + "auth/login", datosEnviar, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(
        (response) => {
          
          if (response.data.State) {
            console.log(response.data.datos);
            localStorage.setItem('username', response.data.datos.email);
            localStorage.setItem('nombre', response.data.datos.nombre);
            localStorage.setItem('admin', response.data.rolname);
            localStorage.setItem('idusuario', response.data.datos.id);
            sessionStorage.setItem('idusuario', response.data.datos.id);
            localStorage.setItem('identificacion', response.data.datos.identificacion);
            localStorage.setItem('cliente_id', response.data.datos.cliente_id);
            localStorage.setItem('jwtto', response.data.token);
            localStorage.setItem('default_color', '#39b530');
            localStorage.setItem('secondary_color', '#411a9e');
            localStorage.setItem('token', Jwt_token);
            setValue(man);
            setName(response.data.datos.nombre);

            var c = response.data.datos.caduca;
            var t = response.data.datos.token_recuperacion;
            console.log(response.data.datos.token_recuperacion);
            if (c == '0') {
              window.location.href = `${process.env.PUBLIC_URL}/dashboard/default/${layout}`;
            } else {
              alert("Tu contraseña esta vencida, debes de cambiar la contraseña para poder ingresar.");
              window.location.href = `${process.env.PUBLIC_URL}/reestablecer/` + t;
            }
            return response;
          } else {
            Swal.fire(response.data.error, '', 'error');
          }
        },
        (error) => {
          Swal.fire('Email o contraseña invalida', '', 'error');
        }
      );
  }


  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>

              <div className="login-main login-tab">
                <div>
                  <a className="logo" href="index.html">
                    <img className="img-fluid for-light" src={require("../assets/images/logov2.png")} alt="" />
                    <img className="img-fluid for-dark" src={require("../assets/images/logov2.png")} alt="" />
                  </a>
                </div>
                <TabContent activeTab={selected} className="content-login" style={{ height: 'auto', paddingBottom: '20px' }}>
                  <TabPane className="fade show" tabId={selected === "firebase" ? "firebase" : "jwt"}>
                    <Form className="theme-form">
                      <h4 className='text-black'>Bienvenidos a TEO</h4>
                      <p>{"Ingrese su email y contraseña"}</p>
                      <div className="mb-3">
                        <Label className="col-form-label text-black">Email</Label>
                        <Input className="form-control" type="email" required="" onChange={e => setEmail(e.target.value)} defaultValue={email} />
                      </div>
                      <div className="mb-3 position-relative">
                        <Label className="col-form-label text-black">Contraseña</Label>
                        <Input className="form-control" type={togglePassword ? "text" : "password"} onChange={e => setPassword(e.target.value)} defaultValue={password} required="" />
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                      </div>
                      <div className="login-btn mb-0">
                        <div className="checkbox ms-3">

                        </div><a className="link" href="recuperar">Perdiste tu contraseña?</a>

                        {Longitude == "" ? <br></br> :
                          <Button color="primary" onClick={() => sesion(email, password)}>Iniciar Sesion</Button>}
                      </div>

                      {Longitude != "" ? '' : <p className="mt-4 mb-0 text-center">Para Iniciar sesion debes de habilitar la geolocalizacion en tu navegador.
                        <br></br><a className="ms-2" href="">Recargar</a></p>}
                    </Form>
                  </TabPane>

                </TabContent>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

// export default withRouter(Logins);
export default Logins;