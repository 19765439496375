import React, { Fragment, useState, useEffect, useCallback,useLayoutEffect } from 'react';
import { Form, Row,Col } from 'reactstrap';
import { X,AlignCenter } from 'react-feather';
import { MENUITEMS } from '../sidebar/menu';
import LeftHeader from './leftbar';
import RightHeader from './rightbar';
import { Link } from 'react-router-dom';
import { Loading } from '../../constant';
import { useSelector } from 'react-redux';
import { DefaultLayout } from '../theme-customizer';
import authAxios from "../../services/Token";

const Header = () => {

    const mainmenu = MENUITEMS;
    const [searchValue, setsearchValue] = useState('');
    const [bonusui, setBonusUI] = useState(false)
     const [sidebartoggle, setSidebartoggle] = useState(true)
     const width = useWindowSize()
    const layout_type = useSelector(content => content.Customizer.layout)
    // const layout_version = useSelector(content => content.Customizer.customizer.color.mix_background_layout);
    const defaultLayout = Object.keys(DefaultLayout);
    const [color, setColor] = useState('white');
    const [Rol, setRol] = useState('');
    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            setsearchValue('')
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);
function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
    const fetchData = async () => {

        // if (rol === null) {
        try {
            const ide = localStorage.getItem('idusuario');
            if (typeof ide !== "undefined") {
              
            }
            else {
              const idea = sessionStorage.getItem('idusuario');
              ide = idea;
            }
            
            const response = await authAxios.get('configuracion/menu/' + ide);
            setColor(localStorage.getItem('color_secundario'));
            setRol(response.data.rol);
        } catch (error) {

        }
        // }
    };

    fetchData();

    const handleSearchKeyword = (keyword) => {
        keyword ? addFix() : removeFix()
        const items = [];
        setsearchValue(keyword)
        mainmenu.map(menuItems => {
            menuItems.Items.filter(mItems => {
                if (mItems.title.toLowerCase().includes(keyword) && mItems.type === 'link') {
                    items.push(mItems);
                }
                if (!mItems.children) return false
                mItems?.children?.filter(subItems => {
                    if (subItems.title.toLowerCase().includes(keyword) && subItems.type === 'link') {
                        subItems.icon = mItems.icon
                        items.push(subItems);
                    }
                    if (!subItems.children) return false
                    subItems.children.filter(suSubItems => {
                        if (suSubItems.title.toLowerCase().includes(keyword)) {
                            suSubItems.icon = mItems.icon
                            items.push(suSubItems);
                        }
                        return suSubItems
                    })
                    return subItems
                })
                checkSearchResultEmpty(items)
                setsearchValue(items);
                return mItems
            });
            return menuItems
        });
    }

    const checkSearchResultEmpty = (items) => {

        if (!items.length) {
            document.querySelector(".empty-menu").classList.add('is-open');
        } else {
            document.querySelector(".empty-menu").classList.remove('is-open');
        }
    }
    const responsive_openCloseSidebar = (toggle) => {
        if (width <= 991) {
          setBonusUI(false)
          document.querySelector(".page-header").className = "page-header";
          document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper "
        } else {
          if (toggle) {
            setSidebartoggle(!toggle);
            document.querySelector(".page-header").className = "page-header close_icon";
            document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon "
            document.querySelector(".mega-menu-container").classList.remove("d-block")
          } else {
            setSidebartoggle(!toggle);
            document.querySelector(".page-header").className = "page-header";
            document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper "
          }
        }
      };
    const addFix = () => {
        document.querySelector(".Typeahead-menu").classList.add('is-open');
        // document.body.classList.add(`${layout_version}`);
        document.body.classList.add(`${layout_type}`);
        document.body.classList.add('offcanvas')
    }

    const removeFix = () => {
        setsearchValue('')
        document.querySelector(".Typeahead-menu").classList.remove('is-open');
        // document.body.classList.add(`${layout_version}`);
        document.body.classList.add(`${layout_type}`);
        document.body.classList.remove('offcanvas')
    }

    console.log("el color es" + color);

    return (
        <Fragment>
            <div className="page-header">
                <Row className="header-wrapper m-0" style={{ background: `${color}`, color: 'white' }}>
                    <Form className="form-inline search-full" action="#" method="get">
                        <div className="form-group w-100">
                            <div className="Typeahead Typeahead--twitterUsers">
                                <div className="u-posRelative">
                                    <input
                                        className="Typeahead-input form-control-plaintext w-100"
                                        id="demo-input"
                                        type="search"
                                        placeholder="Search Cuba .."
                                        defaultValue={searchValue}
                                        onChange={(e) => handleSearchKeyword(e.target.value)}
                                    />
                                    <div className="spinner-border Typeahead-spinner" role="status">
                                        <span className="sr-only">{Loading}</span>
                                    </div><X className="close-search" onClick={() => document.querySelector(".search-full").classList.remove("open")} />
                                </div>
                                <div className="Typeahead-menu custom-scrollbar" id="search-outer">
                                    {searchValue ?
                                        searchValue.map((data, index) => {
                                            return (
                                                <div className="ProfileCard u-cf" key={index}>
                                                    <div className="ProfileCard-avatar">
                                                        <data.icon style={{ color: 'white' }} />
                                                    </div>
                                                    <div className="ProfileCard-details">
                                                        <div className="ProfileCard-realName">
                                                            <Link
                                                                to={data.path + '/' + defaultLayout}
                                                                className="realname"
                                                                onClick={removeFix}
                                                                style={{ color: 'white' }}
                                                            >
                                                                {data.title}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : ''
                                    }
                                </div>
                                <div className="Typeahead-menu empty-menu">
                                    <div className="tt-dataset tt-dataset-0">
                                        <div className="EmptyMessage">
                                            {"Opps!! There are no result found."}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                    {Rol == 9 ?
                        <Fragment>
                            <div className="header-logo-wrapper col-auto p-0" id="out_side_click">
                                <div className="logo-wrapper">
                                    <Link to={`${process.env.PUBLIC_URL}/dashboard/default/`}>
                                        <img className="img-fluid for-light" src={require("../../assets/images/logo/logo-icon3.png")} alt="" />
                                        <img className="img-fluid for-dark" src={require("../../assets/images/logo/logo-icon3.png")} alt="" />
                                    </Link>
                                </div>
                                <div className="toggle-sidebar" onClick={() => responsive_openCloseSidebar(sidebartoggle)} style={window.innerWidth <= 991 ? { display: "block" } : { display: "none" }}>
                                    <AlignCenter className="status_toggle middle sidebar-toggle" id="sidebar-toggle" />
                                </div>
                            </div>
                            <Col className="left-header horizontal-wrapper ps-0">
                                <ul className="horizontal-menu">

                                    
                                    {/* <li className="level-menu outside"><Link className={levelMenu ? "nav-link active" : "nav-link"} to={`${process.env.PUBLIC_URL}/tablero/${layout}`}><User /><span>Tablero de conductores</span></Link>
                          
                        </li> */}
                                </ul>
                            </Col>
                        </Fragment>

                        : <LeftHeader />}
                    <RightHeader />
                </Row>
            </div>
        </Fragment>
    );
}

export default Header;