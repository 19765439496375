import React from 'react';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { DefaultLayout } from '../../layout/theme-customizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faEdit, faFilter, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import tema from "../../services/Plantilla";
import { Overflow } from '../../constant';
import $ from "jquery";
import './index.css';
import CrearServicio from '../Master/CrearServicio';
let fechaactual = new Date().toLocaleString().split(",");
let f = fechaactual[0].split("/");
let hoy = f[2].padStart(2, "0") + "-" + f[1].padStart(2, "0") + "-" + f[0].padStart(2, "0");

let ult = f[2].padStart(2, "0") + "-" + f[1].padStart(2, "0") + "-31";


class Listar extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        load: '',
        datosCargados: false,
        Pilotos: [],
        filteredPilotos: [],
        HorasConductor: [],
        Dias: [],
        Fi: '',
        Ff: ult,
        Conductor: 0,
        Servicio: 0,
        ListaServicio: [],
        Detalle: [],
        CargarDetalle: 0,
        ServicioId: 0,
        pagina: 0,
        actual: 1,
        ultimo: 0,
        Ho: '',
        selectAll: false,
        selectedServices: [],
        ListaEstadosSiniestros: [],
        searchTerm: ''
    }

    // Función para filtrar pilotos por nombre y apellidos
    filterPilotos = () => {
        const { searchTerm, Pilotos } = this.state;
        if (searchTerm) {
            const filtered = Pilotos.filter(piloto =>
                (piloto.nombre + ' ' + piloto.apellidos).toLowerCase().includes(searchTerm.toLowerCase())
            );
            this.setState({ filteredPilotos: filtered });
        } else {
            // Si no hay término de búsqueda, muestra todos los pilotos
            this.setState({ filteredPilotos: Pilotos });
        }
    }
    handleSelectAll = (event) => {
        const isChecked = event.target.checked;

        if (isChecked) {
            // Seleccionar todos los servicios
            const allServiceIds = this.state.ListaServicio.map((item) => item.id);
            this.setState({
                selectAll: true,
                selectedServices: allServiceIds,
            });
        } else {
            // Deseleccionar todos los servicios
            this.setState({
                selectAll: false,
                selectedServices: [],
            });
        }
    };
    toggleServiceSelection = (serviceId) => {
        const { selectedServices } = this.state;

        if (selectedServices.includes(serviceId)) {
            // Deseleccionar el servicio
            this.setState({
                selectedServices: selectedServices.filter((id) => id !== serviceId),
                selectAll: false, // Desmarcar "Seleccionar todos" si se deselecciona un servicio
            });
        } else {
            // Seleccionar el servicio
            this.setState({
                selectedServices: [...selectedServices, serviceId],
                selectAll: selectedServices.length + 1 === this.state.ListaServicio.length, // Marcar "Seleccionar todos" si todos están seleccionados
            });
        }
    };
    // Maneja el cambio en el campo de búsqueda
    handleSearchChange = (event) => {
        this.setState({ searchTerm: event.target.value });
    }

    // Llama a la función de filtrado cuando se hace clic en el botón Filtrar
    handleFilterClick = () => {
        this.filterPilotos();
    }
    LinkEditar(ide) {
        localStorage.setItem('ide', ide);

        window.location.href = process.env.PUBLIC_URL + '/pages/EditarUsuario/' + tema;
    }
    LinkCrear() {
        window.location.href = '/pages/AddUsuarios/';
    }
    Servicios(conductor, ser) {
        Swal.showLoading();
        let usir = localStorage.getItem('idusuario');
        authAxios.get(`control/addservicio/${ser}/${conductor}?extra=${usir}`)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.estado;
                var icon = '';
                if (status == '1') {
                    icon = 'success';
                } else {
                    icon = 'error';
                }

                Swal.fire('Asignacion de servicio!', datosRespuesta.data.msj, icon);

                this.ListarServicios(1);
                //this.setState({ CargarDetalle: 1, ServicioId: '',Detalle:datosRespuesta.data.servicio,Fi:datosRespuesta.data.servicio.fecha,Ho:datosRespuesta.data.servicio.hora_inicial});
            })
            .catch();
    }
    ServiciosMasivoConductor(conductor, ser) {
        Swal.showLoading();
        // Suponiendo que `selectedServices` es una lista de IDs de servicios seleccionados
        const selectedServices = this.state.selectedServices; // o de donde obtengas los servicios seleccionados
        $('#ModalServicioMasivo .btn-close').click() // Cerrar el modal de asignación masiva
        $('#ModalServicio .btn-close').click();
        // Convertir la lista de IDs en una cadena separada por comas
        const servicesString = selectedServices.join(',');
        let usir = localStorage.getItem('idusuario');

        authAxios.get(`control/addservicio-masivo/${servicesString}/${conductor}?extra=${usir}`)
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                Swal.close();
                var status = datosRespuesta.data.estado;
                var icon = '';
                if (status == '1') {
                    icon = 'success';
                } else {
                    icon = 'error';
                }

                Swal.fire('Asignacion de servicio!', datosRespuesta.data.msj, icon);
                $('#ModalServicio .btn-close').click();
                this.ListarServicios(1);

                //this.setState({ CargarDetalle: 1, ServicioId: '',Detalle:datosRespuesta.data.servicio,Fi:datosRespuesta.data.servicio.fecha,Ho:datosRespuesta.data.servicio.hora_inicial});
            })
            .catch();
        $('#ModalServicio .btn-close').click();
    }
    VerServicio(id) {
        Swal.showLoading();

        authAxios.get("control/verservicio/" + id)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;


                this.setState({ CargarDetalle: 1, ServicioId: id, Detalle: datosRespuesta.data.servicio, Fi: datosRespuesta.data.servicio.fecha, Ho: datosRespuesta.data.servicio.hora_inicial });
                this.Lista();
            })
            .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    Lista() {
        Swal.showLoading();
        let bname = document.getElementById('bnombre').value;

        let fi = document.getElementById('bfi').value;
        const datos = { nombre: bname, fecha: fi };

        authAxios.post("control/tablero", datos)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;


                this.setState({ load: '', Pilotos: datosRespuesta.data.pilotos, filteredPilotos: datosRespuesta.data.pilotos, Dias: datosRespuesta.data.horas, HorasConductor: datosRespuesta.data.pilotos });
            })
            .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    ListarServicios(page) {
        //Swal.showLoading();
        let servicio = document.getElementById('bservicio').value;
        const datos = { servicio: servicio };
        authAxios.post("control/servicios?page=" + page, datos)
            .then((datosRespuesta) => {
                // Swal.close();
                var status = datosRespuesta.data.status;


                //this.setState({  ListaServicio: datosRespuesta.data.servicios, pagina: datosRespuesta.data.data.total, ultimo: datosRespuesta.data.data.last_page, actual: datosRespuesta.data.data.current_page });
                this.setState({ ListaServicio: datosRespuesta.data.servicios.data, ListaEstadosSiniestros: datosRespuesta.data.estados, pagina: datosRespuesta.data.servicios.total, ultimo: datosRespuesta.data.servicios.last_page, actual: datosRespuesta.data.servicios.current_page });
                this.setState({ selectedServices: [] });
            })
            .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    AddServicios(id) {
        this.setState({ ServicioId: id });
    }
    componentDidMount() {

        this.ListarServicios(1);
        this.filterPilotos();
    }
    toggleSelectAll = (event) => {
        const { ListaServicio } = this.state;
        if (event.target.checked) {
            // Selecciona todos los IDs de los servicios
            const allIds = ListaServicio.map(service => service.id);
            this.setState({ selectedServices: allIds });
        } else {
            // Deselecciona todos los IDs de los servicios
            this.setState({ selectedServices: [] });
        }
    }

    toggleServiceSelection = (id) => {
        this.setState((prevState) => {
            const { selectedServices } = prevState;
            if (selectedServices.includes(id)) {
                // Si el ID ya está seleccionado, lo elimina
                return { selectedServices: selectedServices.filter(serviceId => serviceId !== id) };
            } else {
                // Si el ID no está seleccionado, lo añade
                return { selectedServices: [...selectedServices, id] };
            }
        });
    }

    sendSelectedServices = () => {
        const { selectedServices } = this.state;
        this.Lista();
        // Enviar los IDs seleccionados a la API
        console.log('Sending selected services:', selectedServices);
        // Aquí deberías llamar a tu API, por ejemplo usando fetch o axios
    }
    render() {
        const { load, Pilotos, HorasConductor, filteredPilotos, ServicioId, Dias, ListaEstadosSiniestros, CargarDetalle, Fi, ListaServicio, Detalle, pagina, ultimo, actual, Ho } = this.state;
        return (
            <div className="content">
                <div className="container-fluid">
                    <br />
                    <div className="card">
                        <div className='row'>
                            <div className='col-xl-8'>
                                <div className="card-body">
                                    <h4>Asignacion de Servicio</h4>

                                    <hr />

                                    <div className="row">
                                        <div className="col-xl-3">
                                            <input style={{ textTransform: 'uppercase' }} type="text" id="bservicio" name="bservicio" placeholder="Buscar Servicio" className="form-control form-control-sm" />
                                        </div>

                                        <div className="col-xl-3">
                                            <button id="bt2" name="bt2" className="btn btn-primary" onClick={() => this.ListarServicios(1)}>&nbsp;&nbsp;Filtrar</button>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row table-responsive" style={{ overflowX: 'auto' }}>
                                        <nav aria-label="Page navigation example float-right">
                                            <ul className="pagination">
                                                <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarServicios(1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                                                <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarServicios(actual - 1)}><FontAwesomeIcon icon={faAngleLeft} /></a></li>
                                                {(() => {
                                                    let p = [];
                                                    p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.BuscarRol(actual)}>{actual}</a></li>);
                                                    return p;
                                                })()}
                                                <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarServicios(actual + 1)}><FontAwesomeIcon icon={faAngleRight} /></a></li>
                                                <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarServicios(ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                                            </ul>
                                        </nav>
                                        <button className="btn btn-success" data-bs-toggle='modal' data-bs-target="#ModalServicioMasivo" onClick={this.sendSelectedServices}>Asignar masivamente</button>
                                        <br />
                                        <table className="table">
                                            <thead className="bg-info" style={{ color: 'white' }}>

                                                <th className="align-middle"><input
                                                    type="checkbox"
                                                    checked={this.state.selectAll}
                                                    onChange={this.handleSelectAll}
                                                />Todos / Cliente</th>
                                                <th className="align-middle">Numero Servicio</th>
                                                <th className="align-middle">Numero Expediente</th>
                                                
                                                <th className="align-middle">Fecha Servicio</th>
                                                <th className="align-middle">Hora</th>
                                                <th className="align-middle">Direccion</th>
                                                <th className="align-middle">Usuario</th>
                                                <th className="align-middle">Ver</th>
                                            </thead>
                                            <tbody>
                                                {
                                                    ListaServicio.map((item, g) => (
                                                        <tr key={g} >

                                                            <td className="align-middle">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={this.state.selectedServices.includes(item.id)}
                                                                    onChange={() => this.toggleServiceSelection(item.id)}
                                                                /> {item.nombre_origen}
                                                            </td>
                                                            <td className="align-middle">{item.numero_expediente}</td>
                                                            <td className="align-middle">{item.orden_servicio_new}</td>
                                                            <td className="align-middle">{item.fecha}</td>
                                                            <td className="align-middle">{item.hora_inicial}</td>
                                                            <td className="align-middle">{item.direccion_inicial}</td>
                                                            <td className="align-middle">{item.nombre_cliente}</td>
                                                            <td className="align-middle"><span className='btn btn-info' onClick={() => this.VerServicio(item.id)}>[+]</span> </td>
                                                        </tr>

                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        <nav aria-label="Page navigation example float-right">
                                            <ul className="pagination">
                                                <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarServicios(1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                                                <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarServicios(actual - 1)}><FontAwesomeIcon icon={faAngleLeft} /></a></li>
                                                {(() => {
                                                    let p = [];
                                                    p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.BuscarRol(actual)}>{actual}</a></li>);
                                                    return p;
                                                })()}
                                                <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarServicios(actual + 1)}><FontAwesomeIcon icon={faAngleRight} /></a></li>
                                                <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarServicios(ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                                            </ul>
                                        </nav>
                                        Total de Registros : {pagina}
                                    </div>
                                </div>
                            </div>

                            <div className='col-xl-4'>
                                <div className="card-body">
                                    <h4>Detalle del servicio {Detalle.id}</h4>
                                    <hr />

                                    <div className="row" >
                                        <div className="card">
                                            <div className="card-body">
                                                Numero de Expediente: {Detalle.numero_expediente}
                                            </div>
                                        </div>

                                        <table className="table-bordered">

                                            <tr>
                                                <td className="align-right">Nombre del cliente</td>
                                                <td className="text-justify" > {Detalle.nombre_cliente} </td>
                                            </tr>
                                            <tr>
                                                <td className="align-right">Ciudad</td>
                                                <td className="text-justify" > {Detalle.nombre_ciudad} </td>
                                            </tr>
                                            <tr>
                                                <td className="align-right">Correo Electronico</td>
                                                <td className="text-justify" > {Detalle.email_cliente}   </td>
                                            </tr>
                                            <tr>
                                                <td className="align-right">Fecha de Servicio</td>
                                                <td className="text-justify" > {Detalle.fecha} </td>
                                            </tr>
                                            <tr>
                                                <td className="align-right">Hora de Servicio</td>
                                                <td className="text-justify" >  {Detalle.hora_inicial}  </td>
                                            </tr>
                                            <tr>
                                                <td className="align-right">Telefono del contacto</td>
                                                <td className="text-justify" > {Detalle.numero_contacto}   </td>
                                            </tr>
                                            <tr>
                                                <td className="align-right">Direccion Inicial</td>
                                                <td className="text-justify" > {Detalle.direccion_inicial}   </td>
                                            </tr>
                                            <tr>
                                                <td className="align-right">Direccion destino</td>
                                                <td className="text-justify" > {Detalle.direccion_final}   </td>
                                            </tr>
                                            <tr>
                                                <td className="align-right">Placa</td>
                                                <td className="text-justify" > {Detalle.placa}   </td>
                                            </tr>


                                            <tr>
                                                <td className="align-right">Observaciones</td>
                                                <td className="text-justify" > {Detalle.observaciones}   </td>
                                            </tr>

                                            <tr>
                                                <td className="align-right">Estado del Servicio</td>
                                                <td className="text-justify" > {Detalle.nombre_estado}   </td>
                                            </tr>




                                        </table>
                                    </div>
                                    {CargarDetalle == '1' ?
                                        <button type="button" className="btn btn-secondary btn-lg btn-block" data-bs-toggle='modal' data-bs-target="#ModalServicio" onClick={() => this.AddServicios(Detalle.id)}>Asignar</button>
                                        : ''}

                                </div>
                            </div>

                        </div>


                    </div>
                </div>

                <div className="modal fade" id="ModalServicio" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
                    <div className={"modal-dialog  modal-custom-size"} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="permisoRolesModalLabel">Asignacion de conductor al Servicio {Detalle.numero_expediente}</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">

                                    <div className="col-xl-3">
                                        <label>Conductor</label>
                                        <input style={{ textTransform: 'uppercase' }} type="text" id="bnombre" name="bnombre" placeholder="Buscar Conductor" className="form-control form-control-sm" />
                                    </div>
                                    <div className="col-xl-3">
                                        <label>Fecha de servicio</label>
                                        <input style={{ textTransform: 'uppercase' }} type="date" id="bfi" name="bfi" defaultValue={Fi} className="form-control form-control-sm" />
                                    </div>
                                    <div className="col-xl-3">
                                        <label>Hora de servicio</label>
                                        <input style={{ textTransform: 'uppercase' }} type="time" id="bho" name="bho" defaultValue={Ho} className="form-control form-control-sm" />
                                    </div>

                                    <div className="col-xl-3">
                                        <button id="bt" name="bt" className="btn btn-primary" onClick={() => this.Lista()}>&nbsp;&nbsp;Filtrar</button>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: "15px",
                                            padding: "10px",
                                        }}
                                    >
                                        {ListaEstadosSiniestros.map((estado) => (
                                            <div
                                                key={estado.id}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "15px",
                                                        height: "15px",
                                                        backgroundColor: estado.color,
                                                        marginRight: "8px",
                                                        border: "1px solid #ddd",
                                                        borderRadius: "4px",
                                                    }}
                                                ></div>
                                                <span>{estado.estado}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <br></br>
                                <div className="row table-responsive" style={{ whiteSpace: 'nowrap', Overflow: 'auto' }}>
                                    <table className="table-bordered">
                                        <thead className="bg-secondary">


                                            <tr>
                                                <th className="align-middle">ITEM</th>
                                                <th className="text-justify" style={{ width: "120px" }}>CONDUCTOR</th>


                                                {
                                                    Dias.map((item, g) => (

                                                        <th key={g} className="align-middle">{item}</th>
                                                    ))
                                                }


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {load}
                                            {
                                                Pilotos.map((row, i) => {
                                                    let horasIniciales = [];

                                                    // Recorrer todas las horas del día para identificar los servicios
                                                    for (let h = 0; h < Dias.length; h++) {
                                                      if (row.pintar_hora[h]?.servicio?.tarifa != undefined) {
                                                        // Guardar la hora inicial, el color y la duración del servicio
                                                        horasIniciales.push({
                                                          horaInicial: h,
                                                          colorInicial: row.pintar_hora[h]?.color_estado,
                                                          horas: row.pintar_hora[h]?.servicio?.tarifa == 5
                                                            ? row.pintar_hora[h].servicio.cantidad
                                                            : row.pintar_hora[h]?.servicio?.tarifa == 352
                                                              ? 14
                                                              : parseInt(row.pintar_hora[h]?.servicio?.diasmoda) || 0
                                                        });
                                                      }
                                                    }
                                                    return (
                                                        <tr key={i}>
                                                                                                                        <td className="align-middle"> <span data-bs-toggle='modal' data-bs-target="#ModalServicio" onClick={() => this.Servicios(row.id, ServicioId)}>[+]</span> {row.id}</td>
                                                          <td className="text-justify">
                                                            {row.nombre} {row.apellidos} {row.mensualidad == 1 && (
                                                              <span style={{ marginLeft: '5px', background: 'green', borderRadius: '50%', padding: '1px' }}>📅</span>
                                                            )}
                                                          </td>
                                                          {/* <td className="text-justify">{row.placa}</td> */}
                                                          {/* <td className="text-justify">{row.tipo}</td> */}
                                                          {Dias.map((it, h) => {
                                                            // Verificar si la hora actual está dentro de algún rango de servicio (excluyendo la última hora)
                                                            const isWithinAnyService = horasIniciales.some(
                                                              servicio => h >= servicio.horaInicial && h < servicio.horaInicial + servicio.horas - 1
                                                            );
                    
                                                            // Obtener el color del servicio activo en esta hora
                                                            const servicioActivo = horasIniciales.find(
                                                              servicio => h >= servicio.horaInicial && h < servicio.horaInicial + servicio.horas - 1
                                                            );
                                                            const colorActivo = servicioActivo ? servicioActivo.colorInicial : null;
                    
                                                            console.log(`Hora: ${h}, isWithinAnyService: ${isWithinAnyService}, colorActivo: ${colorActivo}`); // Depuración
                    
                                                            return (
                                                              <td key={h}>
                                                                <table className="align-middle">
                                                                  <tbody>
                                                                    <tr>
                                                                      {[0, 15, 30, 45].map(minuto => (
                                                                        <td
                                                                          key={minuto}
                                                                          style={{
                                                                            backgroundColor: isWithinAnyService
                                                                              ? colorActivo // Usar el color del servicio activo
                                                                              : row.pintar_hora[h].seleccionar === 'success'
                                                                                ? row.pintar_hora[h].minuto <= minuto
                                                                                  ? row.pintar_hora[h].color_estado
                                                                                  : 'lightgray'
                                                                                : row.pintar_hora[h].seleccionar_fin === 'success'
                                                                                  ? row.pintar_hora[h].minuto > minuto
                                                                                    ? row.pintar_hora[h].color_estado
                                                                                    : 'lightgray'
                                                                                  : 'lightgray',
                                                                            border: '1px solid black'
                                                                          }}
                                                                          onClick={(e) => {
                                                                            const isCircle = this.state.horaSeleccionada === it && this.state.porasignar === true;
                                                                            this.handleRowClick(e, row.pintar_hora[h].servicio, isCircle, row.id);
                                                                          }}
                                                                          title={`${it}:${minuto}`}
                                                                        >
                                                                          {this.state.horaSeleccionada === it && this.state.porasignar == true ? (
                                                                            <span style={{ color: 'blue', fontSize: '24px' }}>&#x25CF;</span>
                                                                          ) : (
                                                                            '..'
                                                                          )}
                                                                        </td>
                                                                      ))}
                                                                    </tr>
                                                                  </tbody>
                                                                </table>
                                                              </td>
                                                            );
                                                          })}
                                                        </tr>
                                                      );
                                                }
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <div className="modal fade" id="ModalServicioMasivo" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
                    <div className={"modal-dialog  modal-xl"} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="permisoRolesModalLabel">Asignacion de conductor Masiva</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">

                                    <div className="col-xl-3">
                                        <label>Conductor</label>
                                        <input
                                            style={{ textTransform: 'uppercase' }}
                                            type="text"
                                            id="bnombre_m"
                                            name="bnombre_m"
                                            placeholder="Buscar Conductor"
                                            className="form-control form-control-sm"
                                            value={this.state.searchTerm}
                                            onChange={this.handleSearchChange}
                                        />
                                    </div>
                                    <div className="col-xl-3">
                                        <label>Fecha de servicio</label>
                                        <input style={{ textTransform: 'uppercase' }} type="date" id="bfi_m" name="bfi_m" defaultValue={Fi} className="form-control form-control-sm" />
                                    </div>
                                    <div className="col-xl-3">
                                        <label>Hora de servicio</label>
                                        <input style={{ textTransform: 'uppercase' }} type="time" id="bho_m" name="bho_m" defaultValue={Ho} className="form-control form-control-sm" />
                                    </div>

                                    <div className="col-xl-3">
                                        <button
                                            id="bt"
                                            name="bt"
                                            className="btn btn-primary"
                                            onClick={this.handleFilterClick}
                                        >
                                            &nbsp;&nbsp;Filtrar
                                        </button>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: "15px",
                                            padding: "10px",
                                        }}
                                    >
                                        {ListaEstadosSiniestros.map((estado) => (
                                            <div
                                                key={estado.id}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "15px",
                                                        height: "15px",
                                                        backgroundColor: estado.color,
                                                        marginRight: "8px",
                                                        border: "1px solid #ddd",
                                                        borderRadius: "4px",
                                                    }}
                                                ></div>
                                                <span>{estado.estado}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <br></br>
                                <div className="row table-responsive" style={{ whiteSpace: 'nowrap', Overflow: 'auto' }}>
                                    <table className="table-bordered">
                                        <thead className="bg-secondary">


                                            <tr>
                                                <th className="align-middle">ITEM</th>
                                                <th className="text-justify" >CONDUCTOR</th>


                                                {
                                                    Dias.map((item, g) => (

                                                        <th key={g} className="align-middle">{item}</th>
                                                    ))
                                                }


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {load}
                                            {filteredPilotos.map((row, i) => {
                                                let horaInicial = null;
                                                let colorInicial = null;
                                                let horas = null;
                                                // if (row.mensualidad === 1) {
                                                for (let h = 0; h < Dias.length; h++) {
                                                    if (row.pintar_hora[h].seleccionar === 'success') {
                                                        horaInicial = h; // Guardar la hora inicial
                                                        colorInicial = row.pintar_hora[h].color_estado; // Guardar el color inicial
                                                        if (row.pintar_hora[h].servicio.modalidad_id == 5) {
                                                            horas = row.pintar_hora[h].servicio.cantidad;
                                                        } else {
                                                            horas = row.pintar_hora[h].servicio.diasmoda;
                                                        }
                                                        break;
                                                    }
                                                }
                                                return (
                                                    <tr key={i}>
                                                        <td className="align-middle">
                                                                        <span data-bs-toggle='modal' data-bs-target="#ModalServicio" onClick={() => this.ServiciosMasivoConductor(row.id, this.props.ServicioId)}>
                                                                            [+]
                                                                        </span> {row.id}
                                                                    </td>
                                                        <td className="text-justify">
                                                            {row.nombre} {row.apellidos} {row.mensualidad == 1 && (
                                                                <span style={{ marginLeft: '5px', background: 'green', borderRadius: '50%', padding: '1px' }}>📅</span> // Icono de calendario
                                                            )}
                                                        </td>

                                                        {Dias.map((it, h) => {
                                                            // Verificar si la hora actual está dentro de las 14 horas siguientes a la hora inicial
                                                            const isWithin14Hours = horaInicial !== null && h >= horaInicial && h < horaInicial + horas;

                                                            return (
                                                                <td key={h}>
                                                                    <table className={"align-middle"}>
                                                                        <tbody>
                                                                            <tr>
                                                                                {[0, 15, 30, 45].map(minuto => (
                                                                                    <td
                                                                                        key={minuto}
                                                                                        style={{
                                                                                            backgroundColor:
                                                                                                row.pintar_hora[h].seleccionar === 'success'
                                                                                                    ? row.pintar_hora[h].minuto <= minuto
                                                                                                        ? row.pintar_hora[h].color_estado
                                                                                                        : 'lightgray'
                                                                                                    : row.pintar_hora[h].seleccionar_fin === 'success'
                                                                                                        ? row.pintar_hora[h].minuto > minuto
                                                                                                            ? row.pintar_hora[h].color_estado
                                                                                                            : 'lightgray'
                                                                                                        : 'lightgray',
                                                                                            border: '1px solid black'
                                                                                        }}
                                                                                        onClick={(e) => this.handleRowClick(e, row)}
                                                                                        title={`${it}:${minuto}`}
                                                                                    >
                                                                                        ..
                                                                                    </td>
                                                                                ))}
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Listar;