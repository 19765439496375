import React from 'react';
import Swal from 'sweetalert2';
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
import $ from "jquery";
import "select2/dist/js/select2.min.js";
import "select2/dist/css/select2.min.css";
import { TimePicker } from 'rsuite';
import 'rsuite/TimePicker/styles/index.css';
import './index.css';

let fechaactual = new Date().toLocaleString().split(",");
let f = fechaactual[0].split("/");
let hoy =
  f[2].padStart(2, "0") +
  "-" +
  f[1].padStart(2, "0") +
  "-" +
  f[0].padStart(2, "0");

class Listar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datosCargados: false,
      ListaTabla: [],
      ListaEstados: [],
      ListaCliente: [],
      ListaCiudades: [],
      ListaCiudadesFiltradas: [],
      ListaDepartamentos: [],
      ListaAseguradoras: [],
      ListaPrefactura: [],
      ListaEstadosSiniestros: [],
      Hoy: hoy,
      seleccionarTodo: false,
      idsSeleccionados: [],
      clientesConBoton: [],
      idClienteInfo: [],
      modalEstadoVisible: false,
      selectedEstado: "",
      obs: "",
      estados: [],
      modalInfoVisible: false,
      modalInfoVisibleobs: false,
      subtotal: 0,
      Pilotos: [],
      filteredPilotos: [],
      HorasConductor: [],
      modalInfoVisibleEditar: false,
      Dias: [],
      modalVisible: false,
      selectedItem: null, // Item seleccionado para cambiar conductor
      conductores: [], // Lista de conductores
      selectedConductor: "",
      tooltip: {
        visible: false,
        x: 0,
        y: 0,
        item: null,
      },
      Usuario: localStorage.getItem('Name')
    }
  }


  closeModal = () => {
    this.setState({ modalVisible: false });
  };
  openModalInfo = (item) => {
    this.setState({
      modalInfoVisible: true,
      selectedItem: this.state.tooltip.item,
    });
  };
  openModalInfoEditar = (item) => {
    this.closeTooltip();
    this.setState({
      modalInfoVisibleEditar: true,
      selectedItem: this.state.tooltip.item,
    });
  };
  closeModalInfoEditar = () => {
    this.setState({ modalInfoVisibleEditar: false });
  };
  openModalInfoObs = (item) => {
    this.setState({
      modalInfoVisibleobs: true,
      selectedItem: this.state.tooltip.item,
    });
  };

  closeModalInfo = () => {
    this.setState({ modalInfoVisible: false });
  };
  closeModalInfoobs = () => {
    this.setState({ modalInfoVisibleobs: false });
  };
  openModalEstado = (item) => {
    console.log(this.state.tooltip.item);
    this.setState({
      modalEstadoVisible: true,
      selectedItem: this.state.tooltip.item,
      selectedEstado: this.state.tooltip.item.estado_servicio, // Selecciona el estado actual
    });
  };

  closeModalEstado = () => {
    this.setState({ modalEstadoVisible: false });
  };
  handleRowClick = (event, item) => {
    const rect = event.target.getBoundingClientRect();
    this.setState({
      tooltip: {
        visible: true,
        x: rect.x - 500,
        y: rect.y + window.scrollY - 60,
        item,
      },
    });
  };
  actualizarConductor(id) {
    Swal.showLoading();
    let usir = localStorage.getItem('idusuario');
    const data = {
      conductor: this.state.selectedConductor,
      usir: usir
    };
    authAxios.post("exterior/actualizar-conductor/" + id, data)
      .then((datosRespuesta) => {
        Swal.close();
        var m = datosRespuesta.data.msj;

        Swal.fire({
          title: m,
          text: 'Ok',
          icon: 'success',
          showConfirmButton: true, // Mostrar el botón "Confirmar"
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.MostrarTabla();
          }
        });
        // this.setState({ idClienteInfo: datosRespuesta.data.datos });
        // this.setState({ datosCargados: true, load: '', ListaCliente: datosRespuesta.data.datos, ListaEstados: datosRespuesta.data.estados, ListaAseguradoras: datosRespuesta.data.asegurdoras });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  actualizarEstado(id) {
    Swal.showLoading();
    const { selectedEstado } = this.state;
    let usir = localStorage.getItem('idusuario');
    const data = {
      estado: selectedEstado,
      usir: usir
    };
    authAxios.post("exterior/actualizar-estado/" + id, data)
      .then((datosRespuesta) => {

        var m = datosRespuesta.data.msj;

        Swal.fire({
          title: m,
          text: 'Ok',
          icon: 'success',
          showConfirmButton: true, // Mostrar el botón "Confirmar"
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.MostrarTabla();
            Swal.close();
          }
        });
        // this.setState({ idClienteInfo: datosRespuesta.data.datos });
        // this.setState({ datosCargados: true, load: '', ListaCliente: datosRespuesta.data.datos, ListaEstados: datosRespuesta.data.estados, ListaAseguradoras: datosRespuesta.data.asegurdoras });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  actualizarObs(id) {
    Swal.showLoading();
    const { obs } = this.state;
    let usir = localStorage.getItem('idusuario');
    const data = {
      estado: obs,
      usir: usir
    };
    authAxios.post("exterior/actualizar-obs/" + id, data)
      .then((datosRespuesta) => {

        var m = datosRespuesta.data.msj;

        Swal.fire({
          title: m,
          text: 'Ok',
          icon: 'success',
          showConfirmButton: true, // Mostrar el botón "Confirmar"
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.setState({
              obs: ""
            });
            this.MostrarTabla();
            this.closeTooltip();
            Swal.close();
          }
        });
        // this.setState({ idClienteInfo: datosRespuesta.data.datos });
        // this.setState({ datosCargados: true, load: '', ListaCliente: datosRespuesta.data.datos, ListaEstados: datosRespuesta.data.estados, ListaAseguradoras: datosRespuesta.data.asegurdoras });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // Método para cerrar el tooltip
  closeTooltip = () => {
    this.setState({
      tooltip: { visible: false, x: 0, y: 0, item: null },
    });
  };
  openModal = (item) => {
    this.setState({
      modalVisible: true,
      selectedItem: this.state.tooltip.item,
      // Conductor actual
    });
  };
  // Agrega un manejador de eventos para el cambio en el campo de selección de departamento
  handleDepartamentoChange = (event) => {
    const departamentoSeleccionado = event.target.value;

    // Filtra la lista de ciudades en función del departamento seleccionado
    const ciudadesFiltradas = this.state.ListaCiudades.filter(
      (ciudad) => ciudad.departamento === departamentoSeleccionado
    );

    this.setState({
      ListaCiudadesFiltradas: ciudadesFiltradas,
    });
  };
  exportToExcel = () => {
    let idx = document.getElementById('idprefactura').value;
    const { ListaPrefactura } = this.state;

    const ws = XLSX.utils.json_to_sheet(ListaPrefactura);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');

    XLSX.writeFile(wb, 'Prefactura' + idx + '.xlsx');
  };
  consultarClienteId(identificacion) {
    console.log(this.state.clientesConBoton)
    Swal.showLoading();
    authAxios.post("exterior/consulta-id-cliente/" + identificacion)
      .then((datosRespuesta) => {
        Swal.close();
        var respuesta = datosRespuesta.data.datos[0];
        console.log(datosRespuesta.data);
        // document.getElementById("tipoDocumento").value = respuesta.tipoDocumento;
        document.getElementById("nombres").value = respuesta.nombre;
        document.getElementById("telefono").value = respuesta.telefono;
        // document.getElementById("departamento").value = respuesta.departamento;
        document.getElementById("documento").value = respuesta.identificacion;
        document.getElementById("email").value = respuesta.email;
        document.getElementById("apellidos").value = respuesta.apellido;
        // document.getElementById("ciudad").value = respuesta.ciudad;
        document.getElementById("direccion").value = respuesta.direccion;

        // this.setState({ idClienteInfo: datosRespuesta.data.datos });
        // this.setState({ datosCargados: true, load: '', ListaCliente: datosRespuesta.data.datos, ListaEstados: datosRespuesta.data.estados, ListaAseguradoras: datosRespuesta.data.asegurdoras });
      })
      .catch((err) => {
        console.log(err);
      });
  }


  GenerarSiigo(id) {
    Swal.showLoading();
    authAxios.post("exterior/exterior-siigo/" + id)
      .then((datosRespuesta) => {
        Swal.close();
        var m = datosRespuesta.data.msj;

        Swal.fire({
          title: m,
          text: 'Ok',
          icon: 'success',
          showConfirmButton: true, // Mostrar el botón "Confirmar"
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.MostrarTabla();
          }
        });
        // this.setState({ idClienteInfo: datosRespuesta.data.datos });
        // this.setState({ datosCargados: true, load: '', ListaCliente: datosRespuesta.data.datos, ListaEstados: datosRespuesta.data.estados, ListaAseguradoras: datosRespuesta.data.asegurdoras });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  guardarCliente = (e) => {

    e.preventDefault();
    Swal.showLoading();
    // Recopila los datos del formulario en un objeto data
    const data = {
      tipoDocumento: document.getElementById('tipoDocumento').value,
      nombres: document.getElementById('nombres').value,
      telefono: document.getElementById('telefono').value,
      departamento: document.getElementById('departamento').value,
      documento: document.getElementById('documento').value,
      email: document.getElementById('email').value,
      apellidos: document.getElementById('apellidos').value,
      ciudad: document.getElementById('ciudad').value,
      direccion: document.getElementById('direccion').value,
    };
    this.setState({ clientesConBoton: [] }, () => {
      // Código que depende del estado actualizado
      // Realiza la solicitud a la API con los datos del formulario
      authAxios.post('exterior/guardar-cliente', data)
        .then(response => {
          Swal.close();
          console.log(this.state.clientesConBoton)
          this.MostrarTabla(1);
          // Maneja la respuesta de la API si es necesario
          console.log(response.data);
          const miBoton = document.getElementById('miBoton');

          // Simula un clic en el botón
          miBoton.click();
        })
        .catch(error => {
          // Maneja los errores si la solicitud falla
          console.error(error);
        });
    });

  }
  componentWillUnmount() {
    clearInterval(this.intervalId); // Limpiar el intervalo cuando el componente se desmonta
  }
  MostrarTabla() {
    this.setState({ load: true, clientesConBoton: [] });
    // Swal.showLoading();
    let bfecha = document.getElementById('bfecha').value;
    let bfecha2 = document.getElementById('bfecha2').value;
    let origen = document.getElementById('borigen').value;
    let estado = document.getElementById('bestado').value;
    let exp = document.getElementById('exp').value;


    var datos = { origen: origen, fechai: bfecha, fechaf: bfecha2, exp: exp, estado: estado }
    this.setState({ clientesConBoton: [] }, () => {
      authAxios.post("exterior/consultaservicio-tiemporeal", datos)
        .then((datosRespuesta) => {
          Swal.close();
          var status = datosRespuesta.data.status;
          // const datos = { nombre: bname, fecha: fi };
          this.setState({ ListaTabla: [] });
          Swal.close();
          this.setState({ datosCargados: true, load: '', Pilotos: datosRespuesta.data.pilotos, filteredPilotos: datosRespuesta.data.pilotos, Dias: datosRespuesta.data.horas, HorasConductor: datosRespuesta.data.pilotos, load: '', ListaTabla: datosRespuesta.data.datos, clientesConBoton: [] });
        })
        .catch(() => {
          this.setState({ clientesConBoton: [] });
        });
    })
    //window.location.href = '/'; window.localStorage.clear();
  }

  handleSelectConductor = (selectedOption) => {
    console.log("Selected:", selectedOption);
    this.setState({ selectedConductor: selectedOption });
  };


  MostrarClientes() {

    authAxios.get("exterior/consultacliente")
      .then((datosRespuesta) => {
        Swal.close();
        var status = datosRespuesta.data.status;
        console.log(datosRespuesta.data);
        this.setState({
          datosCargados: true, load: '', ListaCliente: datosRespuesta.data.datos, ListaEstados: datosRespuesta.data.estados, ListaAseguradoras: datosRespuesta.data.asegurdoras, ListaCiudades: datosRespuesta.data.ciudades,
          ListaDepartamentos: datosRespuesta.data.departamentos,
          ListaEstadosSiniestros: datosRespuesta.data.estados_siniestro,
          estados: datosRespuesta.data.estados_siniestro,
          conductores: datosRespuesta.data.conductores
        });
      })
      .catch();
    //window.location.href = '/'; window.localStorage.clear();
  }
  handlePrint = () => {
    // Obtén el contenido del div que deseas imprimir
    const printContent = document.getElementById("printable-content").innerHTML;

    // Crea una ventana nueva
    const printWindow = window.open("", "_blank");

    // Escribe el contenido en la nueva ventana
    printWindow.document.write(`
      <html>
        <head>
          <title>Imprimir ticket</title>
          <style>
            /* Estilos para la impresión */
            @media print {
            .no-print {
              display: none; /* Oculta elementos con la clase no-print */
            }
          }
            body {
              font-family: Arial, sans-serif;
              margin: 20px;
              background: linear-gradient(135deg, #f5f7fa, #c3cfe2); /* Fondo degradado */
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              
            }
            .logo {
              text-align: center;
              margin-bottom: 20px;
            }
            .logo img {
              max-width: 150px; /* Ajusta el tamaño del logo */
              height: auto;
            }
            h5, h6 {
              color: blue;
              text-align: center;
              font-size: 18px;
              margin:0;
            }
            .row {
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 10px;
            }
            .col-md-6 {
              width: 50%;
            }
            strong {
              font-weight: bold;
            }
            .ticket-content {
              background: #fff; /* Fondo blanco para el contenido */
              padding: 20px;
              border-radius: 10px;
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
              width: 100%;
              max-width: 90%;
              font-size: 14px;
            }
          </style>
        </head>
        <body>
          
          <div class="ticket-content">
          <div class="logo">
            <img src="https://app.transorientesas.com/storage/transoriente/clientes/wfXwWyIwicU2RSsRYKQo7W50knKYCwYY7T8ehdwa.png" alt="Logo de la empresa" /> <!-- Ruta del logo -->
          </div>
            ${printContent}
          </div>
        </body>
      </html>
    `);

    // Cierra el documento para que se cargue el contenido
    printWindow.document.close();

    // Abre el diálogo de impresión
    printWindow.print();
  };
  Enviar = () => {
    let id = document.getElementById('idprefactura').value;
    var dati = { id: id }
    authAxios.post("exterior/consultarprefactura", dati)
      .then((datosRespuesta) => {
        Swal.close();
        var status = datosRespuesta.data.status;
        console.log(datosRespuesta.data.datos);
        this.setState({ ListaPrefactura: datosRespuesta.data.datos });
      })
      .catch();
    //window.location.href = '/'; window.localStorage.clear();
  }
  UpValores(id) {
    const { idsSeleccionados } = this.state;
    let valor = document.getElementById('valor' + id).value;
    var datos = { id: id, valor: valor }
    console.log(idsSeleccionados);
    authAxios.post("exterior/actualizavalor", datos)
      .then((datosRespuesta) => {

        var status = datosRespuesta.data.status;
        this.setState((prevState) => {
          const updatedListaTabla = prevState.ListaTabla.map(item => {
            if (item.id === id) {
              return { ...item, valor_servicio: valor };
            }
            return item;
          });
          return { ListaTabla: updatedListaTabla };
        }, this.calcularSubtotal);

      })
      .catch();
    //window.location.href = '/'; window.localStorage.clear();
  }
  GuardarValores = () => {
    Swal.fire({
      title: 'Está seguro de Generar la prefactura?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Generar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.showLoading();
        let origen = document.getElementById('borigen').value;
        const { idsSeleccionados, Usuario } = this.state;
        if (idsSeleccionados.length === 0) {
          Swal.fire(
            'Debes de seleccionar los servicios',
            '',
            'warning'
          )
          return false;
        }

        var datos = { id: idsSeleccionados, usuario: Usuario, origen: origen }
        console.log(idsSeleccionados);
        authAxios.post("exterior/guardarseleccionado", datos)
          .then((datosRespuesta) => {
            var m = datosRespuesta.data.msj;
            var status = datosRespuesta.data.status;
            document.getElementById('bfecha').value = '';
            document.getElementById('bfecha2').value = '';
            document.getElementById('borigen').value = '';
            document.getElementById('bestado').value = '';
            document.getElementById('ase').value = '';
            document.getElementById('exp').value = '';
            this.setState({
              idsSeleccionados: [],
              subtotal: 0,
            });
            Swal.fire({
              title: m,
              text: 'Facturado',
              icon: 'success',
              showConfirmButton: true, // Mostrar el botón "Confirmar"
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                this.MostrarTabla();
              }
            });
          })
          .catch();
      } else if (result.isDenied) {
        Swal.fire('Cancelado', '', 'info')
      }
    })
  }
  AnularValores = () => {
    Swal.fire({
      title: 'Está seguro de Anular los servicios seleccionados?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Generar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        let origen = document.getElementById('borigen').value;
        const { idsSeleccionados, Usuario } = this.state;
        if (idsSeleccionados.length === 0) {
          Swal.fire(
            'Debes de seleccionar los servicios',
            '',
            'warning'
          )
          return false;
        }

        var datos = { id: idsSeleccionados, usuario: Usuario, origen: origen }
        console.log(idsSeleccionados);
        authAxios.post("exterior/anularseleccionado", datos)
          .then((datosRespuesta) => {
            var m = datosRespuesta.data.msj;
            var status = datosRespuesta.data.status;
            Swal.fire({
              title: m,
              text: 'Facturado',
              icon: 'success',
              showConfirmButton: true, // Mostrar el botón "Confirmar"
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                this.MostrarTabla();
              }
            });

          })
          .catch();
      } else if (result.isDenied) {
        Swal.fire('Cancelado', '', 'info')
      }
    })
  }
  handleSelectAll = () => {
    this.setState((prevState) => {
      const { ListaTabla, seleccionarTodo } = prevState;
      const nuevosIdsSeleccionados = !seleccionarTodo ? ListaTabla.map((item) => item.id) : [];
      return {
        seleccionarTodo: !seleccionarTodo,
        idsSeleccionados: nuevosIdsSeleccionados,
      };
    }, this.calcularSubtotal);
  };
  formatDate(dateString) {
    const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const months = [
      'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
      'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
    ];

    console.log(dateString);
    const date = new Date(dateString + 'T00:00:00');
    console.log(date);
    const dayOfWeek = days[date.getDay()]; // Obtiene el día de la semana (Lunes, Martes, etc.)
    console.log(dayOfWeek);
    const dayOfMonth = date.getDate(); // Obtiene el día del mes (1, 2, 3, ..., 31)
    console.log(dayOfMonth);
    const month = months[date.getMonth()]; // Obtiene el nombre del mes (enero, febrero, etc.)
    const year = date.getFullYear(); // Obtiene el año (2025)

    return `${dayOfWeek}, ${dayOfMonth} de ${month} de ${year}`;
  }
  guardarEdicion = (id) => {
    Swal.showLoading();
    const { selectedItem } = this.state;
    let usir = localStorage.getItem('idusuario');
    const data = {
      orden_servicio_new: selectedItem.orden_servicio_new,
      estado_nombre: selectedItem.estado_nombre,
      numero_contacto: selectedItem.numero_contacto,
      fecha: selectedItem.fecha,
      hora_inicial: selectedItem.hora_inicial,
      placa_vh: selectedItem.placa_vh,
      direccion_inicial: selectedItem.direccion_inicial,
      direccion_final: selectedItem.direccion_final,
      observaciones: selectedItem.observaciones,
      movil_conductor: selectedItem.movil_conductor,
      valor_servicio: selectedItem.valor_servicio,
      usir: usir,
      tarifa: selectedItem.tarifa,
      cantidad: selectedItem.cantidad,
    };

    authAxios.post("exterior/actualizar-datos/" + id, data)
      .then((response) => {
        Swal.fire({
          title: response.data.msj,
          text: 'Actualización exitosa',
          icon: 'success',
          showConfirmButton: true,
          allowOutsideClick: false,
        }).then((result) => {
          this.MostrarTabla();
          // if (result.isConfirmed) {
          this.MostrarTabla();
          Swal.close();
          this.setState({ modalInfoVisibleEditar: false });
          // }
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: 'Error',
          text: 'No se pudo actualizar la información',
          icon: 'error',
          showConfirmButton: true,
        });
      });
  };
  parseTimeStringToDate = (timeString) => {
    console.log("intento pasrsear" + timeString);
    if (!timeString) return null; // Maneja casos donde el valor es nulo o indefinido
    const [hours, minutes] = timeString.split(":");
    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));
    return date;
  };
  formatDateToTimeString = (date) => {
    if (!date) return ""; // Maneja casos donde el valor es nulo o indefinido
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}:00`; // Formato HH:mm:ss
  };
  handleChange = (field, value) => {
    // Si el valor es un evento estándar de HTML, extrae el valor de `target.value`
    if (value && value.target) {
      value = value.target.value;
    }

    // Si el campo es "hora_inicial", formatea el valor a una cadena de tiempo
    if (field === "hora_inicial") {
      value = this.formatDateToTimeString(value);
    }

    // Actualiza el estado
    this.setState((prevState) => ({
      selectedItem: {
        ...prevState.selectedItem,
        [field]: value,
      },
    }));
  };

  handleCheckboxChange = (id) => {
    this.setState((prevState) => {
      const { idsSeleccionados } = prevState;
      const updatedIds = idsSeleccionados.includes(id) ? idsSeleccionados.filter(itemId => itemId !== id) : [...idsSeleccionados, id];
      return { idsSeleccionados: updatedIds };
    }, this.calcularSubtotal);
  };
  calcularSubtotal = () => {
    const { ListaTabla, idsSeleccionados } = this.state;
    const subtotal = ListaTabla.reduce((total, item) => {
      console.log(item);
      return idsSeleccionados.includes(item.id) ? total + parseFloat(item.facturado) : total;
    }, 0);
    this.setState({ subtotal });
  }
  componentDidMount() {
    this.intervalId = setInterval(() => {
      this.MostrarTabla();
    }, 15000); // 15 segundos
    this.MostrarClientes(1);
    $("#selectConductor").select2({
      placeholder: "Seleccione un conductor",
      allowClear: true,
    });
    $("#borigen").select2({
      placeholder: "Seleccione un cliente",
      allowClear: true,
    });


  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.modalVisible && !prevState.modalVisible) {
      // Reaplica Select2 si el modal se vuelve visible
      $("#selectConductor").select2({
        placeholder: "Seleccione un conductor",
        allowClear: true,
      });
      $("#borigen").select2({
        placeholder: "Seleccione un cliente",
        allowClear: true,
      });
      $("#selectConductor").on("change", (e) => {
        console.log("log");
        this.setState({ selectedConductor: e.target.value });
      });
    }
  }
  render() {
    const { load, ListaCiudadesFiltradas, ListaTabla, Dias, Pilotos, ListaEstadosSiniestros, subtotal, pagina, ultimo, ListaPrefactura, actual, Hoy, ListaAseguradoras, ListaEstados, ListaCliente, seleccionarTodo, idsSeleccionados, ListaDepartamentos, ListaCiudades, tooltip } = this.state;
    return (
      <div className="content">
        <div className="container-fluid">
          <br />
          <div className="card">

            <div className="card-body">

              <h4>Lista Servicios En tiempo Real</h4>
              <hr />
              <div className="row">
                <div className="col-xl-2">
                  <select id="borigen" name="borigen" className="form-control form-control-sm" >
                    <option value={''}>Seleccione el cliente</option>
                    {
                      ListaCliente
                        .map((item, i) => (
                          <option key={i} value={item.id}>{item.nombre}</option>
                        ))
                    }
                  </select>
                </div>
                <div className="col-xl-2">
                  <select id="bestado" name="bestado" className="form-control form-control-sm" >
                    <option value={''}>Seleccione estado</option>
                    {
                      ListaEstadosSiniestros.map((ite, i) => (
                        <option key={ite.id} value={ite.id}>{ite.id} - {ite.estado}</option>
                      ))}
                  </select>
                </div>

                <div className="col-xl-2">
                  <input style={{ textTransform: 'uppercase' }} type="text" id="exp" name="exp" placeholder="Buscar por expediente" className="form-control form-control-sm" />
                </div>
                <div className="col-xl-2">
                  <input style={{ textTransform: 'uppercase' }} type="date" id="bfecha" name="bfecha" placeholder="Buscar por expediente" className="form-control form-control-sm" />
                </div>
                <div className="col-xl-2">
                  <input style={{ textTransform: 'uppercase' }} type="date" id="bfecha2" name="bfecha2" placeholder="Buscar por fecha" className="form-control form-control-sm" />
                </div>
                <div className="col-xl-2">
                  <button id="bt" name="bt" className="btn btn-primary btn-sm" onClick={() => this.MostrarTabla(1)}>&nbsp;&nbsp;Filtrar</button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "15px",
                  padding: "10px",
                }}
              >
                {ListaEstadosSiniestros.map((estado) => (
                  <div
                    key={estado.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        backgroundColor: estado.color,
                        marginRight: "8px",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                      }}
                    ></div>
                    <span>{estado.estado}</span>
                  </div>
                ))}
              </div>
              {this.state.load ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Cargando...</span>
                  </div>
                </div>
              ) : ''}
              <br /><br />
              <div className="row table-responsive">

                <table className="table-hover table-condensed table-sm">
                  <thead className="thead-inverse table-success">
                    <tr>
                      <th className="align-middle">ID</th>
                      <th className="align-middle">NUMERO EXP</th>
                      <th className="align-middle">CLIENTE</th>
                      <th className="align-middle">USUARIO</th>
                      <th className="align-middle">FECHA SERVICIO</th>
                      <th className="align-middle">HORA</th>
                      <th className="align-middle">PLACA</th>
                      <th className="align-middle">CONDUCTORES</th>
                      <th className="align-middle">ESTADO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ListaTabla.map((item) => (
                      <tr
                        key={item.id}
                        style={{
                          background: `${item.color_estado}`,
                          cursor: "pointer",
                          transition: "background-color 0.3s", // Suaviza la transición
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.filter = "brightness(0.85)") // Oscurece el color
                        }
                        onMouseLeave={(e) => (e.currentTarget.style.filter = "brightness(1)")} // Restaura el color original
                        onClick={(e) => this.handleRowClick(e, item)}
                      >
                        <td className="text-white">{item.id}</td>
                        <td className="text-white">{item.orden_servicio_new}{item.posicion != '' ? '-' + item.posicion : ''}</td>
                        <td className="text-white">{item.nombre_clientea}</td>
                        <td className="text-white">{item.nombre_cliente}</td>
                        <td className="text-white">{item.fecha}</td>
                        <td className="text-white">{item.hora_inicial}</td>
                        <td className="text-white">{item.placa_vh}</td>
                        <td className="text-white">{item.nombre_conductor}</td>
                        <td className="text-white">{item.estado_nombre}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {tooltip.visible && (
                  <div
                    style={{
                      position: "absolute",
                      top: tooltip.y,
                      left: tooltip.x,
                      backgroundColor: "#fff",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      zIndex: 1000,
                      padding: "10px",
                      width: "500px"
                    }}
                  >
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => this.openModalEstado(tooltip.selectedItem)}
                    >
                      Cambiar Estado
                    </button>
                    <button
                      className="btn btn-warning btn-sm mx-2"
                      onClick={() => this.openModal(tooltip.selectedItem)}
                    >
                      Cambiar Conductor
                    </button>
                    <button
                      className="btn btn-info btn-sm"
                      onClick={() => this.openModalInfo(tooltip.selectedItem)}
                    >
                      Ver Información
                    </button>
                    <button
                      className="btn btn-info btn-sm mx-2"
                      onClick={() => this.openModalInfoEditar(tooltip.selectedItem)}
                    >
                      Editar Información
                    </button>
                    <button
                      className="btn btn-secondary btn-sm"
                      onClick={() => this.openModalInfoObs(tooltip.selectedItem)}

                    >
                      Agregar Observaciones
                    </button>
                    <button
                      className="btn btn-danger btn-sm mt-2"
                      onClick={this.closeTooltip}
                      style={{ display: "block", marginTop: "10px" }}
                    >
                      Cerrar
                    </button>
                  </div>
                )}
              </div>

            </div>
            <div className="card-footer text-muted">
              <b>Pagina</b> <span className="badge bg-primary">{actual}</span> <b>de</b> <span className="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span className="badge bg-success">{pagina}</span>
            </div>
          </div>
        </div>
        {
          this.state.modalInfoVisibleEditar && (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "#fff",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                padding: "20px",
                width: "500px",

              }}
            >
              <h4 className="mb-4">Editar Información - {this.state.selectedItem?.nombre_cliente}</h4>

              <div>
                <h5 className="text-primary">General</h5>
                <div className="row">
                  <div className="col-md-6">
                    <label>Expediente:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.selectedItem?.orden_servicio_new}
                      onChange={(e) => this.handleChange("orden_servicio_new", e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Estado:</label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={this.state.selectedItem?.estado_nombre}
                      onChange={(e) => this.handleChange("estado_nombre", e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Teléfono:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.selectedItem?.numero_contacto}
                      onChange={(e) => this.handleChange("numero_contacto", e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Fecha:</label>
                    <input
                      type="date"
                      className="form-control"
                      value={this.state.selectedItem?.fecha}
                      onChange={(e) => this.handleChange("fecha", e.target.value)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <label>Hora:</label>

                    <TimePicker
                      name="horaInicial"
                      format="HH:mm" // Formato de 24 horas
                      value={this.parseTimeStringToDate(this.state.selectedItem?.hora_inicial)} // Valor actual
                      onChange={(time) => {
                        console.log(time); // Verifica qué se está pasando en el evento
                        this.handleChange("hora_inicial", time); // Pasa el valor directamente
                      }}
                      hideMinutes={(minute) => minute % 15 !== 0} // Mostrar solo minutos múltiplos de 15
                      editable={false} // No permitir edición manual
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Placa:</label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={this.state.selectedItem?.placa_vh}
                      onChange={(e) => this.handleChange("placa_vh", e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Origen:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.selectedItem?.direccion_inicial}
                      onChange={(e) => this.handleChange("direccion_inicial", e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Destino:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.selectedItem?.direccion_final}
                      onChange={(e) => this.handleChange("direccion_final", e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <label>Modalidad:</label>
                    <select
                      className="form-control"
                      value={this.state.selectedItem?.tarifa || ""}
                      onChange={(e) => this.handleChange("tarifa", e.target.value)}
                    >
                      <option value="">Seleccione una modalidad</option>
                      <option value="1">MENSUALIDAD 10 HORAS</option>
                      <option value="2">MENSUALIDAD 12 HORAS</option>
                      <option value="3">MENSUALIDAD 14 HORAS</option>
                      <option value="4">MES</option>
                      <option value="5">HORA</option>
                      <option value="6">RECORRIDO</option>
                      <option value="7">DIA</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label>Valor Servicio:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.selectedItem?.valor_servicio}
                      onChange={(e) => this.handleChange("valor_servicio", e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Cantidad Horas:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.selectedItem?.cantidad}
                      onChange={(e) => this.handleChange("cantidad", e.target.value)}
                      disabled={this.state.selectedItem?.tarifa != "5"} // Deshabilitar si no es "HORA"
                    />
                  </div>
                  <div className="col-md-12">
                    <label>Observaciones:</label>
                    <textarea
                      className="form-control"
                      value={this.state.selectedItem?.observaciones}
                      onChange={(e) => this.handleChange("observaciones", e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <hr />

              <button
                className="btn btn-primary mt-3"
                onClick={() => this.guardarEdicion(this.state.selectedItem?.id)}
              >
                Actualizar Cambios
              </button>
              <button
                className="btn btn-secondary mt-3 ml-3"
                onClick={this.closeModalInfoEditar}
              >
                Cerrar
              </button>
            </div>
          )
        }
        {this.state.modalVisible && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#fff",
              borderRadius: "10px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",

              padding: "20px",
              width: "400px",
            }}
          >
            <h5>Cambiar Conductor - Servicio {this.state.selectedItem?.orden_servicio_new}</h5>
            <p><strong>Conductor Actual:</strong> {this.state.selectedItem?.nombre_conductor}</p>
            <div className="form-group">
              <label htmlFor="selectConductor">Seleccionar Nuevo Conductor:</label>
              <select
                id="selectConductor"
                className="form-control"
                style={{ zIndex: 9999 }}
                value={this.state.selectedConductor}
                onChange={this.handleSelectConductor}
                placeholder="Seleccione un conductor"
              >
                {this.state.conductores.map((conductor, index) => (
                  <option key={index} value={conductor.identificacion}>
                    {conductor.nombre}
                  </option>
                ))}
              </select>
            </div>
            <button
              className="btn btn-primary mt-3"
              onClick={() => {
                console.log("Nuevo Conductor:", this.state.selectedConductor);
                this.actualizarConductor(this.state.selectedItem.id);
                this.closeModal();
              }}
            >
              Guardar
            </button>
            <button
              className="btn btn-secondary mt-3 ml-2"
              onClick={this.closeModal}
            >
              Cancelar
            </button>
          </div>
        )}
        {this.state.modalEstadoVisible && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#fff",
              borderRadius: "10px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              padding: "20px",
              width: "400px",
            }}
          >
            <h5>Cambiar Estado - Servicio {this.state.selectedItem?.orden_servicio_new}</h5>
            <p>
              <strong>Estado Actual:</strong>{" "}
              {this.state.selectedItem?.estado_nombre}
            </p>
            <div className="form-group">
              <label htmlFor="selectEstado">Seleccionar Nuevo Estado:</label>
              <select
                id="selectEstado"
                className="form-control"
                style={{ zIndex: 9999 }}
                value={this.state.selectedEstado}
                onChange={(e) =>
                  this.setState({ selectedEstado: e.target.value })
                }
              >
                {this.state.estados.map((estado, index) => (
                  <option key={index} value={estado.id}>
                    {estado.estado}
                  </option>
                ))}
              </select>
            </div>
            <button
              className="btn btn-primary mt-3"
              onClick={() => {
                console.log("Nuevo Estado:", this.state.selectedEstado);
                this.actualizarEstado(this.state.selectedItem.id);
                this.closeModalEstado();
              }}
            >
              Guardar
            </button>
            <button
              className="btn btn-secondary mt-3 ml-2"
              onClick={this.closeModalEstado}
            >
              Cancelar
            </button>
          </div>
        )}
        {this.state.modalInfoVisible && (
          <div
            id="printable-content"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#fff",
              borderRadius: "10px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              padding: "10px",
              maxHeight: "80vh", // Ajusta este valor según tus necesidades
              overflowY: "auto", // Permite el desplazamiento vertical si el contenido es demasiado largo
              zIndex: "99999",
              width: "90%", // Ajusta el ancho si es necesario
              maxWidth: "600px", // Ajusta el ancho máximo si es necesario
            }}
          >
            <div class="container border p-3 custom-border">
              <div class="row text-center">
                <div class="col-6 border"><img style={{ marginTop: '5px' }} src={'https://app.transorientesas.com/storage/' + this.state.selectedItem?.imagen_clientea} width={150} alt="" /></div>
                {/* <div class="col-2 border"></div> */}
                <div class="col-6 border"><img src="https://app.transorientesas.com/storage/transoriente/clientes/wfXwWyIwicU2RSsRYKQo7W50knKYCwYY7T8ehdwa.png" alt="" /></div>
              </div>
              <div class="row text-center border mt-2">
                <div class="col"><strong>{this.state.selectedItem?.nombre_clientea}</strong></div>
              </div>
              <div class="row border">
                <div class="col-4 border"><strong>EXPEDIENTE: </strong> <br />
                  {this.state.selectedItem?.orden_servicio_new}{this.state.selectedItem?.posicion != '' ? '-' + this.state.selectedItem?.posicion : ''}</div>
                <div class="col-4 border"><strong>ESTADO DEL SERVICIO: </strong> <br />
                  {this.state.selectedItem?.estado_nombre}</div>
                <div class="col-4 border"><strong>HORA MILITAR: </strong> <br />{this.state.selectedItem?.hora_inicial}</div>
              </div>
              
              <div class="row border">
                <div class="col-4 border"><strong>FECHA: </strong><br />{this.formatDate(this.state.selectedItem?.fecha)}</div>
                <div class="col-4 border"><strong>MODALIDAD: </strong><br />{this.state.selectedItem?.modalidad_tarifa_nombre}</div>
                <div class="col-4 border"><strong>DISPONIBILIDAD: </strong><br />{this.state.selectedItem?.tarifa == 1 ? '12 Horas' :
                  this.state.selectedItem?.tarifa == 2 ? '14 Horas' :
                    this.state.selectedItem?.tarifa == 3 ? '16 Horas' :
                      this.state.selectedItem?.tarifa == 4 ? '10 Horas' :
                        this.state.selectedItem?.tarifa == 6 ? '2 Horas' :
                          this.state.selectedItem?.tarifa == 7 ? '10 Horas' :
                            this.state.selectedItem?.tarifa == 5 ? `${parseInt(this.state.selectedItem?.cantidad) || 0} Horas` : '14 Horas'}</div>
              </div>
              <div class="row border">
                <div class="col-12 border"><strong>PRODUCCION: </strong> <br />
                {this.state.selectedItem?.produccion}</div>
              </div>

              <div class="row text-center border mt-2">
                <div class="col"><strong>DATOS DEL USUARIO</strong></div>
              </div>
              <div class="row border">
                <div class="col-6 border"><strong>NOMBRE COMPLETO: </strong><br />{this.state.selectedItem?.nombre_cliente}</div>
                <div class="col-6 border"><strong>CELULAR: </strong><br />{this.state.selectedItem?.numero_contacto}</div>
              </div>
              <div class="row border">
                <div class="col-6 border"><strong>ORIGEN: </strong><br />{this.state.selectedItem?.direccion_inicial}</div>
                <div class="col-6 border"><strong>DESTINO: </strong><br />{this.state.selectedItem?.direccion_final}</div>
              </div>

              <div class="row text-center border mt-2">
                <div class="col-12"><strong>OBSERVACIONES</strong></div>
                <div class="col-12">{this.state.selectedItem?.observaciones}</div>
              </div>

              <div class="row text-center border mt-2">
                <div class="col"><strong>DATOS DEL CONDUCTOR</strong></div>
              </div>
              <div class="row border">
                <div class="col-4 border"><strong>NOMBRE:</strong><br />{this.state.selectedItem?.nombre_conductor}</div>
                <div className="col-4 border">
                  <div
                    style={{
                      backgroundColor: '#FFD700',  // Amarillo similar a las placas de vehículos
                      color: '#000',
                      fontWeight: 'bold',
                      fontSize: '14px',
                      border: '2px solid #000',
                      borderRadius: '8px',
                      padding: '5px 15px',
                      display: 'block',        // Permite usar margin auto
                      margin: '10px auto', 
                      minWidth: '100px',
                      width:'150px',
                      textAlign: 'center',
                      letterSpacing: '2px'
                    }}
                  >
                    {this.state.selectedItem?.placa_vh}
                  </div>
                </div>
                <div class="col-4 border"><strong>TELÉFONO:</strong><br />{this.state.selectedItem?.movil_conductor}</div>
              </div>
              <button
                className="btn btn-secondary mt-3 mr-3 no-print"
                onClick={this.closeModalInfo}
              >
                Cerrar
              </button>
            </div>



            {/* <button
             className="btn btn-primary mt-3 no-print" // Estilo del botón
             onClick={this.handlePrint} // Manejador de eventos
           >
             Imprimir ticket
           </button> */}
          </div>
        )}
        {this.state.modalInfoVisibleobs && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#fff",
              borderRadius: "10px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              padding: "20px",
              width: "500px",
              zIndex: "99999",
            }}
          >
            <h4 className="mb-4">Agregar Obs - {this.state.selectedItem?.nombre_cliente}</h4>

            <div>
              <h5 className="text-primary">General</h5>
              <div className="row">
                <div className="col-md-6">
                  <p>
                    <strong>Expediente:</strong> {this.state.selectedItem?.orden_servicio_new}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Estado:</strong> {this.state.selectedItem?.estado_nombre}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Teléfono:</strong> {this.state.selectedItem?.numero_contacto}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Fecha:</strong> {this.state.selectedItem?.fecha}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <p>
                    <strong>Hora:</strong> {this.state.selectedItem?.hora_inicial}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Placa:</strong> {this.state.selectedItem?.placa_vh}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Origen:</strong> {this.state.selectedItem?.direccion_inicial}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Destino:</strong> {this.state.selectedItem?.direccion_final}
                  </p>
                </div>
                <div className="col-md-12">
                  <p>
                    <strong>Observaciones:</strong> {this.state.selectedItem?.observaciones}
                  </p>
                  <textarea onChange={(e) =>
                    this.setState({ obs: e.target.value })
                  } value={this.state.obs} name="" className='form-control' id=""></textarea>
                </div>
              </div>
            </div>
            <button
              className="btn btn-primary mt-3"
              onClick={() => {
                console.log("Nuevo obs:", this.state.obs);
                this.actualizarObs(this.state.selectedItem.id);
                this.closeModalInfoobs();
              }}
            >
              Guardar
            </button>
            <button
              className="btn btn-secondary mt-3"
              onClick={this.closeModalInfoobs}
            >
              Cerrar
            </button>
          </div>
        )}

        {/* modal crear clinente */}
        <div className="modal fade" id="FormularioModalCreacionCliente" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
          <div className={"modal-dialog  modal-lg"} role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="permisoRolesModalLabel">Creacion Cliente </h4>
                <button id='miBoton' type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <input type="hidden" id="item" name="item" />
              </div>
              <div className="modal-body">
                <div id="form_registro_crear_cliente">

                  <div className="card-body">
                    <div className="card-body btn-showcase">
                      <div id="formulario">
                        <div className="row">
                          <div className="col-xl-12">
                            <form onSubmit={this.guardarCliente}>
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="tipoDocumento">Tipo Documento</label>
                                    <select class="form-control" id="tipoDocumento" aria-describedby="tipoDocumentoHelp">
                                      <option value="1">CC</option>
                                      <option value="2">CE</option>
                                      <option value="3">NIT</option>
                                    </select>
                                    <small id="tipoDocumentoHelp" class="form-text text-muted">Selecciona el tipo de documento.</small>
                                  </div>
                                  <div class="form-group">
                                    <label for="nombres">Nombres</label>
                                    <input type="text" class="form-control" id="nombres" aria-describedby="nombresHelp" />
                                    <small id="nombresHelp" class="form-text text-muted">Ingresa tu nombre.</small>
                                  </div>
                                  <div class="form-group">
                                    <label for="telefono">Telefono</label>
                                    <input type="tel" class="form-control" id="telefono" aria-describedby="telefonoHelp" />
                                    <small id="telefonoHelp" class="form-text text-muted">Ingresa tu número de teléfono.</small>
                                  </div>
                                  <div class="form-group">
                                    <label for="departamento">Departamento</label>
                                    {/* <input type="text" class="form-control" id="departamento" aria-describedby="departamentoHelp" /> */}
                                    <select id="departamento" onChange={this.handleDepartamentoChange} name="departamento" className="form-control form-control-sm">
                                      <option value={''}>Seleccione el departamento</option>
                                      {
                                        ListaDepartamentos.map((item, i) => (
                                          <option value={item.id} >{item.id} - {item.nombre}</option>
                                        ))}
                                    </select>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="documento">Documento</label>
                                    <input type="text" class="form-control" id="documento" aria-describedby="documentoHelp" />
                                    <small id="documentoHelp" class="form-text text-muted">Ingresa tu número de documento.</small>
                                  </div>
                                  <div class="form-group">
                                    <label for="email">Email</label>
                                    <input type="email" class="form-control" id="email" aria-describedby="emailHelp" />
                                    <small id="emailHelp" class="form-text text-muted">Ingresa tu dirección de correo electrónico.</small>
                                  </div>
                                  <div class="form-group">
                                    <label for="apellidos">Apellidos</label>
                                    <input type="text" class="form-control" id="apellidos" aria-describedby="apellidosHelp" />
                                    <small id="apellidosHelp" class="form-text text-muted">Ingresa tus apellidos.</small>
                                  </div>
                                  <div class="form-group">
                                    <label for="ciudad">Ciudad</label>
                                    <select id="ciudad" name="ciudad" className="form-control form-control-sm">
                                      <option value={''}>Seleccione el ciudad</option>
                                      {
                                        ListaCiudadesFiltradas.map((item, i) => (
                                          <option value={item.id} >{item.id} - {item.nombre}</option>
                                        ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label for="direccion">Direccion</label>
                                <input type="text" class="form-control" id="direccion" aria-describedby="direccionHelp" />
                                <small id="direccionHelp" class="form-text text-muted">Ingresa tu dirección.</small>
                              </div>
                              <button type="submit" class="btn btn-primary mt-4">Guardar</button>
                            </form>




                          </div>
                        </div>

                      </div>

                      <div className="modal-footer">
                        {/* <button onClick={this.exportToExcel} className="btn btn-primary">Guardar Cliente</button> */}
                        <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                      </div>

                    </div>


                  </div>
                </div>
              </div>
            </div>

          </div>
        </div >


        <div className="modal fade" id="FormularioModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
          <div className={"modal-dialog  modal-xl"} role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="permisoRolesModalLabel">Detalle del servicio <span id='servicio'></span></h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <input type="hidden" id="item" name="item" />
              </div>
              <div className="modal-body">
                <div id="form_registro">

                  <div className="card-body">
                    <div className="card-body btn-showcase">
                      <div id="formulario">
                        <div className="row">
                          <div className="col-xl-12">
                            <div className='row g-3'>


                              <div className="col-md-12">
                                <label htmlFor="idprefactura">Id Prefactura </label>
                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm mt-2 mb-4"} type="text" name="idprefactura" id="idprefactura" aria-describedby="helpId" />

                              </div>



                            </div>

                            <table className="table table-hover table-condensed table-sm">

                              <thead className="thead-inverse  table-success ">
                                <tr>
                                  <th className="align-middle">ITEM</th>
                                  <th className="align-middle">PREFACTURA</th>
                                  <th className="align-middle">DOCUMENTO</th>
                                  <th className="align-middle">NOMBRE USUARIO</th>
                                  <th className="align-middle">CANT DE SERVICIO</th>
                                  <th className="align-middle">TOTAL</th>
                                  <th className="align-middle">ACCION</th>

                                </tr>
                              </thead>
                              <tbody>

                                {
                                  ListaPrefactura.map((item, i) => (
                                    <tr key={item.id}>
                                      <td>{i + 1}</td>
                                      <td>{item.id_prefactura}</td>
                                      <td>{item.documento}</td>
                                      <td>{item.nombre_cliente}</td>
                                      <td>{item.servicio}</td>
                                      <td>{item.total}</td>
                                      <td><button className='btn btn-primary btn-sm' onClick={() => this.GenerarSiigo(item.id_prefactura)}> Generar SIIGO </button></td>

                                    </tr>
                                  ))
                                }
                              </tbody>

                            </table>

                          </div>
                        </div>
                        <hr />
                        <div className='row'>
                          <div className='col-xl-12 text-center'>
                            <div className="btn-group" role="group" aria-label="">
                              <button type="button" onClick={this.Enviar} className="btn btn-success btn-sm" id="btnservicio"><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Consultar Prefactura</button>&nbsp;&nbsp;


                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button onClick={this.exportToExcel} className="btn btn-primary">Descargar Excel</button>
                        <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                      </div>

                    </div>


                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>




      </div >
    );
  }
}

export default Listar;